import React from 'react';
import { AppRelevantDataContext } from '../../../AppContext';
import { FaSignOutAlt, FaUser, FaQuestion} from 'react-icons/fa';
import {Navigate} from 'react-router-dom';
import { NavItem } from 'reactstrap';
import { ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import App from '../../../App'; 
import { IDS_LogoutConfirmation, IDS_Support, IDS_PhoneNumberErrMsg,
      IDS_FAQs, IDS_Logout, IDS_Name, IDS_EmailID, IDS_PhoneNo, IDS_NumberOfOwnDevice, IDS_NumberOfVisbleDevices, IDS_AUSrvrIssueReqParamsNotSent,
      IDS_UserProfile, IDS_UserProfileError, IDS_LoginServerIssue, IDS_RegistNetworkError,  APPLANG_EN, APPLANG_MR, APPLANG_HI, IDS_TrackToilet, IDS_Close,
      IDS_ChangeUserPassword, IDS_EditUserProfile, IDS_NewAndOldPassNotMatched, IDS_NewPassCannotSameAsOldPass, IDS_SuccessChangedUsrPasswordReLogin,
      IDS_OldPassIncorrect, IDS_ChangePassword, IDS_Edit, IDS_OldPass, IDS_NewPass, IDS_CnfrmPass, IDS_FirstName, IDS_LastName, IDS_Save, IDS_EnterFirstName,
      IDS_EnterLastName, IDS_SuccessUpdatedUsrProf, IDS_OldPassError, IDS_NewPassError, IDS_CnfrmPassErrro, IDS_OldPassLengthError, IDS_NewPassLengthError, IDS_ConfrimPassLengthError,
      IDS_ShowPassword,
      IDS_ToggleTree,
      IDS_MonitorDevices,
      IDS_CRM,
      IDS_SanitationMapping, IDS_ParamDocumentation} from '../../../VcLanguage';
import axios from 'axios';
import { getAPIHostURL } from '../../../ClientConfig';
import { PVG_DEVC_OWNER, PVG_GENERAL_USER, PVG_DEVC_VIEWER, PVG_SALES_EXECUTIVE, PVG_SALES_SUPERVISOR, 
         PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER, PVG_BACK_OFFICE_EXECUTIVE, PVG_PRODUCT_OWNER, 
         PVG_FIELD_ENGINEER, PVG_INST_PRIMARY_OWNER, PVG_INST_ADDITIONAL_VIEWER, PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING, 
         PVG_DEVC_VIEWER_WITH_SETTING, PVG_TOILET_ADMIN, PVG_SW_DEV_ADMIN, PVG_TREE_DEFINITION_ADMIN, PVG_TREE_NODE_VIEWER, CLIENT_TYPE, PVG_EXTERNAL_API_USER} from '../../../VcConstants';
import { TiArrowBack } from 'react-icons/ti';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { RiCustomerService2Fill } from 'react-icons/ri';
import { TiDocumentText } from "react-icons/ti";
import { SiGoogleforms } from "react-icons/si";
import { MdCleanHands } from "react-icons/md";


import vilisoFavIcon from './../../IMAGES/VilisoFavIcon.png';
import { BsFillMenuButtonWideFill } from 'react-icons/bs';
import VcSetupDevice from '../VcSetupDevice';
import { FaMapLocationDot } from 'react-icons/fa6';
import { TbListTree } from 'react-icons/tb';
import { connect } from 'react-redux';
import { clearDeviceList } from '../Store/Slices/deviceList';
import { clearTreeNodeList } from '../Store/Slices/treeNodeList';

 class VcSideNav extends React.Component { 
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {  
      redirect: false,   
      isOpen: false,
      ReportDropDownOpen: false,
      treeDefNavActive: false,
      calibAppLinks: false,
      ActionDropDownOpen: false,
      DeviceActionDropDownOpen: false,
      ProductActionDropDownOpen: false,
      TrackDropDownOpen: false,
      OrderDropDownOpen: false,
      showEditForm: false,
      RawDataParametersActionDropDownOpen: false,
      calibAppDropDownOpen: false,
      toggleCrmSmartHmmApp: false,
      smartHHMAppDropDownOpen: false,
      MoreDropDownOpen: false,
      ComplaintManagementDropDownOpen: false,
      ProductPartActionDropDownOpen: false,
      showEditProfileFields: "",
      userID: "",
      ownDeviceCount: "",
      visibleDeviceCount: "",
      userPhoneNo: "",
      firstName: "",
      lastName: "",
      CountryInfo: [],
      CountryCode: "",
      oldPassword: "",
      newPassword: "",
      cnfrmNewPassword: "",
      passwordShown: false,
      errors: { 
        others:'',
      },
      EncryptedPvg:"",
      PrivilegeEncKey:"",
      objPrivilege:{},
      languageToViewIn:'',
      showRootClose: true,
      deviceMenuPvg: false,
      crmMenuPvg: false,
      arrOfAllSessionIDs: '',
    };
  }
  
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  saveDeviceRegIdIntoDB = async (userEmailId, currToken, newToken, action, sessionID, inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let sessID = "";

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    sessID = sessionID != null ? sessionID : null;
    let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn

    var requestObject = {
      "UserID": userEmailId,
      "CurrentToken": currToken,
      "NewToken": newToken,
      "UserClientLanguage": languageToViewIn,
      "UserClientMode": "WEB",
      "Action": action,
      "SessionID": sessID
    };

    return await axios.post( `${getAPIHostURL()}/wclient/UpdateDeviceNotificationRegID`, requestObject )
    .then(response => {
      if(response.data.code == 'SUCCESS') {
        // console.log("Successfully Updated Notification ID");
      } else {
        if(response.data.code == 'REQ_PARAMS_MISSING'){
          modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);

        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          console.log("Should not reach here.")
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
      }
      return [response.data.code, modifiedState];
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError)
      this.setState(modifiedState);
      return;
    }); 
  }

  

  onLogOut = (invokeFrom, inModifiedState = null) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let sessEncChallan = appRelevantDataContextValue.sessJankari.sessChallan;
    let sessChaabi = appRelevantDataContextValue.sessJankari.sessChaabi;
    let sessID = appRelevantDataContextValue.sessJankari.sessID;
    let fcmRegID = appRelevantDataContextValue.fcmRegDetails.fcmRegID;

    if((invokeFrom == "LogoutButton" && window.confirm(t(IDS_LogoutConfirmation))) || (invokeFrom == "ChangePassword")) {
      // User wants to Log Out

      this.saveDeviceRegIdIntoDB(LoggedInUserID, fcmRegID, "null", 'Logout', sessID, modifiedState).then((response) => {

        let responseCode = response[0];
        let updatedState = response[1];

        if(responseCode == "SUCCESS") {

          const { nodeList, deviceList } = this.props;

          if(nodeList != null && nodeList.length > 0) {
            this.props.clearTreeNodeList();
          }
          
          if(deviceList != null && deviceList.length > 0) {
            this.props.clearDeviceList();
          }

          this.endDateSesJankari(updatedState);          

        } else {
          this.setState(updatedState);
        }

      })
      .catch((error) => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError)
        this.setState(modifiedState);
      });
      // Here, On Log out End date the current session of Logged in user.
      
    }
  }

  renderRedirect = () => {
    if(this.state.redirect) {
      return <Navigate to="/"/>
    }
  }

  endDateSesJankari = (inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let sessID = appRelevantDataContextValue.sessJankari.sessID;

    let jsonParams = {
      LoggedInUserID: LoggedInUserID,
      // strChalan: strChalan,
      sessID: sessID
    }
    
    axios.post(`${getAPIHostURL()}/wclient/endDateSesJankari`, jsonParams)
    .then(response =>{

        if(response.data.code == 'SUCCESS') {

          // In VcLoginPg component on useEffect we added check if sesstion ID present in context than don't login page, 
          // redirect to default home page as per privilage and only if sesstion ID not present in context than only show login page form.
          // Bellow clearAppRelevantDataContextFromLocalStorage function was taking time to clear and set default empty context.
          // hence page redirect to login component before context get clean and its throws authorization failed message after logout.
          // so we explicitly clean the "onChangeSessJankari" context so after logout we can show login page.
          appRelevantDataContextValue.onChangeSessJankari("", "", "");

          // User wants to Log Out
          // Clear the AppRelevantDataContext that has been stored in the LocalStorage,
          // (Except UserID and Language Selection and a few other default values).
          App.clearAppRelevantDataContextFromLocalStorage();

          modifiedState.redirect = true;
          
          this.setState(modifiedState);

        } else {
          if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
              console.log("Should not reach here");
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          this.setState(modifiedState);

      }
    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          this.setState(modifiedState);
        }
    })

  }


  onChangeLanguageToViewIn = async (e) => {
    let strLanguageToViewIn = e.target.value;
    let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

    let modifiedState = this.state;
    // Save into the App Context any current Date changes that might have happened on the UI
    // before the Chart Param type was changed
    await appRelevantDataContextValue.onChangeLanguageToViewIn(strLanguageToViewIn);

    let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let sessID = appRelevantDataContextValue.sessJankari.sessID;
    let fcmRegID = appRelevantDataContextValue.fcmRegDetails.fcmRegID;

    modifiedState.languageToViewIn = strLanguageToViewIn;

    this.saveDeviceRegIdIntoDB(LoggedInUserID, fcmRegID, "null", "LanguageChange", sessID, modifiedState).then((response) => {
      let updatedState = response[1];

      this.setState(updatedState);

    })

  }

  componentDidMount () {
    this.getUserPrivileges();
    this.getAllCountryCodeInfo();

  }

  componentDidUpdate () {
  
    let currentState = this.state;
    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

    if( appRelevantDataContextValue == null ||
        ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.loggedInUserInfo == null ||
        ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
        appRelevantDataContextValue.loggedInUserInfo.userID == null ||
        appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
        ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.selectedNodeInfo == null  
    ) {
        console.log("NavBar:componentDidUpdate - First time render. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
        return; // Do not process further
    }

    if(appRelevantDataContextValue.loggedInUserPrivilege.Privilege != currentState.EncryptedPvg) {
      // on Refresh values stored in LocalStorage doesnot refelect into appcontext hence 
      // we need to call componenentDidUpdate which again take privilege from context and store into state.
      this.getUserPrivileges();
    }
  }

  // check loggedIn user privileges.
  getUserPrivileges = () => {
    let modifiedState = this.state;
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;  

    let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
    modifiedState.EncryptedPvg = encryptedPrivileges;

    axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
    .then(response =>{
      modifiedState.errors.others = "";
        if(response.data.code == 'SUCCESS') {
           if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                modifiedState.errors.others = `Unable to get encryption key.`;
           } else {
               modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

              //  let bytes  = CryptoJS.AES.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
               let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);

              //  let strPrivilege = bytes.toString(CryptoJS.enc.Utf8);
               let strPrivilege = bytes.toString(enc);

               try {
                    modifiedState.objPrivilege = JSON.parse(strPrivilege);

                    modifiedState.deviceMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_OWNER) && modifiedState.objPrivilege[PVG_DEVC_OWNER] == true ) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && modifiedState.objPrivilege[PVG_GENERAL_USER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER) && modifiedState.objPrivilege[PVG_DEVC_VIEWER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_DEVC_VIEWER_WITH_SETTING] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_PRIMARY_OWNER) && modifiedState.objPrivilege[PVG_INST_PRIMARY_OWNER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_NODE_VIEWER) && modifiedState.objPrivilege[PVG_TREE_NODE_VIEWER] == true) );

                    modifiedState.crmMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && modifiedState.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && modifiedState.objPrivilege[PVG_PRODUCTION_WORKER] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && modifiedState.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && modifiedState.objPrivilege[PVG_SALES_SUPERVISOR] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && modifiedState.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && modifiedState.objPrivilege[PVG_PRODUCT_OWNER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && modifiedState.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && modifiedState.objPrivilege[PVG_TOILET_ADMIN] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && modifiedState.objPrivilege[PVG_SW_DEV_ADMIN] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && modifiedState.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && modifiedState.objPrivilege[PVG_EXTERNAL_API_USER] == true)) ;
                    
                      if ((modifiedState.deviceMenuPvg != null && modifiedState.deviceMenuPvg) && (modifiedState.crmMenuPvg != null && modifiedState.crmMenuPvg) && 
                        appRelevantDataContextValue.navMenu.menuSwitchBtn != null && appRelevantDataContextValue.navMenu.menuSwitchBtn.length <= 0) {
                        appRelevantDataContextValue.onChangeSwitchBtn("DeviceMenu")   
                      }  
               } catch(e) {
                  // this.props.history.replace('/');
                  console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
               }
           }

        } else {
            // this.props.history.replace('/');
            if(response.data.code == 'SQL_ERROR') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here");
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            console.log(modifiedState.errors.others);
        }
        this.setState(modifiedState);

    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          console.log(modifiedState.errors.others);
          this.setState(modifiedState);
        }
    }) 
  }

  getAllCountryCodeInfo = () => {

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;

    axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformation`)
    .then(response => {
      if(response.data.code == "SUCCESS") {
          modifiedState.CountryInfo = [];
          modifiedState.CountryInfo = response.data.CountryInfo;
      } else {
        if(response.data.code == "SQL_ERROR"){
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          modifiedState.successfulRegisterMsg = t(IDS_LoginServerIssue);
        }
      }
      this.setState(modifiedState);
    })
    .catch(error => {
      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      } 
    });
  }

  getLoggedInUserInfo = () => {
    let modifiedState = this.state;

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    
    let OwnerUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    modifiedState.userID = OwnerUserID;

    let jsonBody = {
      OwnerUserID: OwnerUserID,
      ClientType: CLIENT_TYPE
    }

    axios.post(`${getAPIHostURL()}/wclient/getLoggedInUserInfo`, jsonBody)
    .then(response => {

      if(response.data.code == 'SUCCESS') {
       
        if(response.data.DeviceIdCount == null || response.data.DeviceIdCount.length <= 0 || 
           response.data.VisibleDeviceIDCount == null || response.data.VisibleDeviceIDCount.length <= 0 
          //  ||
          //  response.data.UserPhoneNo == null || response.data.UserPhoneNo.length <= 0  
        ) {
          // modifiedState.errors.others = `User Profile Information Missing.`;
          modifiedState.errors.others = t(IDS_UserProfileError);
          
        } else {
          if( response.data.UserPhoneNo == null || response.data.UserPhoneNo.length <= 0 ){
            modifiedState.errors.others = t(IDS_UserProfileError);
          }
          let NumberofDeviceCount = response.data.DeviceIdCount;
          let visibleDeviceIDCount = response.data.VisibleDeviceIDCount;
          let userPhoneNo = response.data.UserPhoneNo || 'NA';
          let UserFirstName = response.data.UserFirstName;
          let UserLastName = response.data.UserLastName;

          modifiedState.ownDeviceCount = NumberofDeviceCount;
          modifiedState.visibleDeviceCount = visibleDeviceIDCount;
          modifiedState.userPhoneNo = (userPhoneNo != null && userPhoneNo.length > 0 && userPhoneNo.split(" ")[1] != null && userPhoneNo.split(" ")[1].length > 0) ? userPhoneNo.split(" ")[1] : userPhoneNo;
          modifiedState.firstName = UserFirstName;
          modifiedState.lastName = UserLastName;
          modifiedState.CountryCode = (userPhoneNo != null && userPhoneNo.length > 0 && userPhoneNo.startsWith("+") && userPhoneNo.split(" ")[0] != null && userPhoneNo.split(" ")[0].length > 0) ? userPhoneNo.split(" ")[0] : "";
          this.setState(modifiedState);
        }
      } else {

          if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAM_MISSING') {
            modifiedState.errors.others = t(IDS_LoginServerIssue);

          } else if(response.data.code == 'USER_PROFILE_INFO_MISSING' || response.data.code == "INVALID_USER_EMAILID") {
              modifiedState.errors.others = t(IDS_UserProfileError);

          } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
      }
      this.setState(modifiedState);
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      }
    })
  }

  onBackButton = () => {
    window.close();
    let appRelevantDataContextValue = this.context;
    appRelevantDataContextValue.onChangeTrackDeviceInfo([], {}, "");
    appRelevantDataContextValue.onSelectedDevice("", "");

  }

  toggleRootCloseValueBasedOnSetupDeviceModal = () => {
    this.setState(prevState => ({
      showRootClose: !prevState.showRootClose
    }))
  }

  onEditButtonclick = ()=> {
    this.setState(prevState => ({
      showEditForm: !prevState.showEditForm,
      showRootClose: !prevState.showRootClose,
      CountryCode: prevState.CountryCode != null && prevState.CountryCode.length > 0 ? prevState.CountryCode : "+91",
      showEditProfileFields: "EditProfile",
    }))
  }

  onClickChangePassword = ()=> {
    this.setState(prevState => ({
      showEditForm: !prevState.showEditForm,
      showRootClose: !prevState.showRootClose,
      showEditProfileFields: "ChangePassword",
    }))
  }

  onCloseButtonclick = ()=> {
    let modifiedState = this.state;
    modifiedState.showRootClose = true;
    modifiedState.errors.others = '';
    this.setState(modifiedState);
    document.body.click();
  }

  onChnageFisrtName = (e) => {
    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.firstName = e.target.value;
    this.setState(modifiedState);
  }

  onChnageLastName = (e) => {
    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.lastName = e.target.value;
    this.setState(modifiedState);
  }

  onChnagePhoneNo = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.userPhoneNo = e.target.value;
    if(modifiedState.userPhoneNo.length < 4 || modifiedState.userPhoneNo.length > 12) {
      modifiedState.errors.others = t(IDS_PhoneNumberErrMsg);
    }
    this.setState(modifiedState);
  }

  onChangeOldPassword = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.oldPassword = e.target.value;
    if(modifiedState.oldPassword != null && modifiedState.oldPassword.length < 8) {
      modifiedState.errors.others = t(IDS_OldPassLengthError);
    } else {
      modifiedState.errors.others = '';
    }
    this.setState(modifiedState);
  }

  onChnageNewPassword = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.newPassword = e.target.value;
    if(modifiedState.newPassword != null && modifiedState.newPassword.length < 8) {
      modifiedState.errors.others = t(IDS_NewPassLengthError);
    } else {
      modifiedState.errors.others = '';
    }
    this.setState(modifiedState);
  }

  onChnageCnfrmNewPassword = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.cnfrmNewPassword = e.target.value;
    if(modifiedState.cnfrmNewPassword != null && modifiedState.cnfrmNewPassword.length < 8) {
      modifiedState.errors.others = t(IDS_ConfrimPassLengthError);
    } else {
      modifiedState.errors.others = '';
    }
    this.setState(modifiedState);
  }

  onEditCloseButtonclick = (e) => {
    let modifiedState = this.state;
    modifiedState.errors.others = "";
    modifiedState.oldPassword = "";
    modifiedState.newPassword = "";
    modifiedState.cnfrmNewPassword = "";
    modifiedState.showEditProfileFields = "";
    modifiedState.showRootClose = true;
    modifiedState.showEditForm = false;
    this.setState(modifiedState);
    document.body.click();
  }

  onSaveButtonclick = (e) => {

    let modifiedState =  this.state;
    let inAppRelevantDataContextValue = this.context;
    let t = inAppRelevantDataContextValue.t
    let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
    
    if(modifiedState.userPhoneNo == null || modifiedState.userPhoneNo.length < 4 || modifiedState.userPhoneNo.length > 12 ) {
      modifiedState.errors.others = t(IDS_PhoneNumberErrMsg);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.firstName == null || modifiedState.firstName.length <= 0){
      modifiedState.errors.others = t(IDS_EnterFirstName);
      this.setState(modifiedState);
      return;
    } else if (modifiedState.lastName == null || modifiedState.lastName.length <= 0) {
      modifiedState.errors.others = t(IDS_EnterLastName);
      this.setState(modifiedState);
      return;

    } else{
      // No need to proccess.
    }

    let jsonParams = {
      firstName: modifiedState.firstName,
      lastName: modifiedState.lastName,
      phoneNo: modifiedState.CountryCode +" " +modifiedState.userPhoneNo,
      loggedInUserID: loggedInUserID,
    }

    axios.post(`${getAPIHostURL()}/wclient/updateUserInfo`, jsonParams)
    .then(response =>{

        if(response.data.code == 'SUCCESS') {
          inAppRelevantDataContextValue.onChangeloggedInUserInfo(modifiedState.firstName, modifiedState.lastName);

          alert(t(IDS_SuccessUpdatedUsrProf))

          modifiedState.showRootClose = true;
          // modifiedState.firstName = "";
          // modifiedState.lastName = "";
          modifiedState.showEditForm = false;
          this.setState(modifiedState);

          document.body.click(); 
          document.body.click(); 

        } else {
            if(response.data.code == 'REQ_PARAMS_MISSING'){
                modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
            } else if(response.data.code == 'SQL_ERROR') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here");
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
          this.setState(modifiedState);
        }
    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
            console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        this.setState(modifiedState);
    }) 
  }

  changeUserPassword = (e) => {

    let modifiedState =  this.state;
    let inAppRelevantDataContextValue = this.context;
    let t = inAppRelevantDataContextValue.t
    let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
    let sessID = inAppRelevantDataContextValue.sessJankari.sessID;

    if(modifiedState.oldPassword == null || modifiedState.oldPassword.length <= 0) {
      modifiedState.errors.others = t(IDS_OldPassError);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.newPassword == null || modifiedState.newPassword.length <= 0) {
      modifiedState.errors.others = t(IDS_NewPassError);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.cnfrmNewPassword == null || modifiedState.cnfrmNewPassword.length <= 0) {
      modifiedState.errors.others = t(IDS_CnfrmPassErrro);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.oldPassword.length < 8) {
      modifiedState.errors.others = t(IDS_OldPassLengthError);
      this.setState(modifiedState);
      return;  
    } else if(modifiedState.newPassword.length < 8) {
      modifiedState.errors.others = t(IDS_NewPassLengthError);
      this.setState(modifiedState);
      return;      
    } else if (modifiedState.newPassword.length != modifiedState.cnfrmNewPassword.length ||
      modifiedState.newPassword != modifiedState.cnfrmNewPassword
    ) {
      modifiedState.errors.others = t(IDS_NewAndOldPassNotMatched);
      this.setState(modifiedState);
      return;
    } else if (modifiedState.oldPassword.length == modifiedState.cnfrmNewPassword.length && 
      modifiedState.oldPassword == modifiedState.cnfrmNewPassword
    ) {
      modifiedState.errors.others = t(IDS_NewPassCannotSameAsOldPass);
      this.setState(modifiedState);
    return;
    } else{
      // No need to proccess.
    }

    let jsonParams = {
      OldPassword: modifiedState.oldPassword,
      NewPassword: modifiedState.newPassword,
      CnfrmNewPassword: modifiedState.cnfrmNewPassword,
      LoggedInUserID: loggedInUserID,
    }

    axios.post(`${getAPIHostURL()}/wclient/changeUserPassword`, jsonParams)
    .then(response =>{

        if(response.data.code == 'SUCCESS') {

          // alert(t(IDS_SuccessChnagedUsrPassword))
          alert(t(IDS_SuccessChangedUsrPasswordReLogin));

          modifiedState.showRootClose = true;
          modifiedState.oldPassword = "";
          modifiedState.newPassword = "";
          modifiedState.cnfrmNewPassword = "";
          modifiedState.showEditProfileFields = "";
          modifiedState.showEditForm = false;

          // Here, On ChangePassword, End date the current session of Logged in user.
          let jsonParams = {
            LoggedInUserID: loggedInUserID,
          }

          axios.post(`${getAPIHostURL()}/wclient/bringAllSessIDsOfLoggedInUser`, jsonParams)
          .then(response =>{
      
              if(response.data.code == 'SUCCESS') {

                if(response.data.allSessionIDsOfLoggedInUser == null || response.data.allSessionIDsOfLoggedInUser.length <= 0 )
                {
                    console.log("Should not reach here.");
                } else {
                    let allSessionIDsOfLoggedInUser = response.data.allSessionIDsOfLoggedInUser;
                    let arrayOfSessionIDs = [];

                    for(let i=0; i<allSessionIDsOfLoggedInUser.length; i++){
                      arrayOfSessionIDs.push(allSessionIDsOfLoggedInUser[i]["SessionID"]);
                    }

                    modifiedState.arrOfAllSessionIDs = arrayOfSessionIDs.join(",");

                }

                this.onLogOut("ChangePassword", modifiedState);

              } else {
                // this.props.history.replace('/');
                if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);

            }
          })
          .catch(err => {
              console.log("Network error");
              console.log(err);
              if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
              } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
              }
          }) 
      
          this.setState(modifiedState);

          document.body.click(); 
          document.body.click(); 

        } else {

          if(response.data.code == 'REQ_PARAMS_MISSING'){
              modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
          } else if(response.data.code == 'INCORECT_OLD_PASSWORD') {
            modifiedState.errors.others = t(IDS_OldPassIncorrect);
          } else if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
              console.log("Should not reach here");
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          this.setState(modifiedState);
        }
    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
            console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        this.setState(modifiedState);
    }) 
  }

  onChangeCountryCode = (e) => {
    let modifiedState = this.state;
    modifiedState.CountryCode = e.target.value;
    this.setState(modifiedState);
  }

  switchMenu = (inMenuType) => {
    let appRelevantDataContextValue = this.context;
    
    if(inMenuType != null && inMenuType == "CrmMenu") {
      appRelevantDataContextValue.onChangeSwitchBtn("CrmMenu")  
    } else {
      appRelevantDataContextValue.onChangeSwitchBtn("DeviceMenu")
    }
  }

 

  onTogglePassword = () => {
    let modifiedState = this.state;
    modifiedState.passwordShown = !modifiedState.passwordShown;
    this.setState(modifiedState);
  }

  render() {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

    let userFirstName = appRelevantDataContextValue.loggedInUserInfo.userFirstName;
    let userLastName = appRelevantDataContextValue.loggedInUserInfo.userLastName;
    let trackedDeviceInfoArr = appRelevantDataContextValue.devicesToTrack.DevicesInfo;
    let ownerOfTrackedDevices = appRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
    let bShowMenuBasedOnTracedDevcsKeyValue =  ((trackedDeviceInfoArr != null && trackedDeviceInfoArr.length > 0) || (ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0)) ? true : false;  

    let menuSwitchBtn = appRelevantDataContextValue.navMenu.menuSwitchBtn;
    let invokedLoginPgInfo = appRelevantDataContextValue.invokedLoginPgInfo;
    let loginPagePath = invokedLoginPgInfo.loginPagePath;
    let showNextPagePathAfterLogin = invokedLoginPgInfo.showNextPagePathAfterLogin;

    let bShowMenuBasedOnLoginPathKeyValue =  ((invokedLoginPgInfo != null && Object.keys(invokedLoginPgInfo).length > 0) &&
      (loginPagePath != null && loginPagePath.length > 0) && (showNextPagePathAfterLogin != null 
        && showNextPagePathAfterLogin.length > 0)) ? true : false;
    let pathname = window.location.pathname;
    // console.log(pathname);
    let windowWidth = window.innerWidth;
    
    return (
      <div style={{position:"sticky", top:"0"}}>
        <div className='flex flex-col  justify-between items-center pb-4 pt-2 sideNavBg' style={{position:"relative", height:'100vh'}}>  
          <div className='flex flex-col gap-2 flex-center'>


          {(bShowMenuBasedOnTracedDevcsKeyValue) &&
              <span className='' style={{display:"inline"}}>
                <NavItem className="side-nav c-pointer" >
                  {this.renderRedirect()}
                  <span style={{color: "white"}} onClick = {this.onBackButton} className="closeCurrentTab">
                    <div className="tooltipPar">
                      <TiArrowBack/>
                        {/* <span className="tooltiptextRight">{t(IDS_Logout)}</span> */}
                      <span className="tooltiptextRight">Go Back</span>
                    </div>
                  </span>
                </NavItem>
              </span>
            }


            {
              this.props.toggleDeviceTreeVisible != null && pathname != "/device/allDevices" ? (
                  <span 
                  className={`side-nav `}
                   onClick={this.props.toggleDeviceTreeVisible}  >
                  <span 
                  >
                    <span className="tooltipPar c-pointer"> 
                        <TbListTree style={{fontSize:"1.6rem", fontStyle:"bold"}}/> 
                        <span className="tooltiptextRight">{t(IDS_ToggleTree)}</span>
                    </span>
                  </span>
                  
                </span>
                ) : 
                // !pathname.includes("/crm/") ? (
                  <span 
                    className='border border-success text-success fw-3 flex-center bg-white rounded' 
                    style={{ width: '30px', height: '30px', overflow: "hidden" }}
                  >
                    <img src={vilisoFavIcon} alt="" style={{ maxWidth: "100%" }} />
                  </span>
                // ) : null
            }

            {
              ((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg == true) && 
                !bShowMenuBasedOnLoginPathKeyValue
              )
              &&
                <span className="">
                  <NavLink to = "/device/dashboard"
                    
                    // className = {`side-nav ${this.state.active == 'DeviceMenu' && 'side-nav-active'}`}
                    className={({ isActive }) => ((isActive || pathname.includes("/device/")) ? "side-nav-active side-nav" : 'side-nav')}
                    onClick = {() => {
                        this.switchMenu("DeviceMenu")
                    }}
                  >
                    {/* <MdDevices/> */}
                    <div className="tooltipPar">
                          {/* <MdDevices/> */}
                          <BsFillMenuButtonWideFill/>
                          <span className="tooltiptextRight">{t(IDS_MonitorDevices)}</span>
                      </div>
                  </NavLink>
              </span>
            }

            {
              (
                (this.state.crmMenuPvg != null && this.state.crmMenuPvg == true) && 
                (!bShowMenuBasedOnTracedDevcsKeyValue) && (!bShowMenuBasedOnLoginPathKeyValue)
              ) 
              &&
              <span className="">
                {
                  <NavLink to=
                    {
                    ((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                    (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true))
                    ? 
                      `/crm/report/currentStateInventory`
                    :
                    ((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                    (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true))
                    ?
                      `/crm/report/salesDashboard`
                    :
                    (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true)
                    ?
                      `/crm/report/inactiveFieldDevices`
                    :
                    (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                    (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) 
                    ?
                      `/crm/report/deviceHistory`
                    :
                    (this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true)
                    ?
                      `/crm/report/viewToiletAndItsRatingInfo`
                    :
                    (this.state.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && this.state.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)
                    ?
                      `/crm/more/treeDefinition`
                    :
                    (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && this.state.objPrivilege[PVG_PRODUCT_OWNER] == true)
                    ?
                      `/crm/productAction/productDetails`
                    :
                    (this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true)
                    ?
                      `/crm/action/productItemState`
                    :
                    (this.state.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && this.state.objPrivilege[PVG_EXTERNAL_API_USER] == true) 
                    ?
                      `/crm/vilisoApis`
                    : null
                  }
                  style={{textDecoration:"none"}}
                  className={({ isActive }) => ((isActive || pathname.includes("/crm/")) ? "side-nav-active side-nav" : 'side-nav')}
                > 
                  { (
                      !bShowMenuBasedOnLoginPathKeyValue
                    ) &&
                      <span
                        className='side-nav'
                        onClick = {() => {
                          this.switchMenu("CrmMenu")
                      }}
                      >
                          <div className="tooltipPar">
                              <small className={pathname.includes("/crm/") ? "crmIconActive" : "crmIcon"}>CRM</small>
                              <span className="tooltiptextRight">{t(IDS_CRM)}</span>
                          </div>
                      </span>
                  }
              </NavLink>
                }
              </span>
            }

            { ((this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true) ||
                (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) &&
                !bShowMenuBasedOnLoginPathKeyValue
              ) && 
              <span className="">
                <NavLink to='/sanitationMapping' 
                className={({ isActive }) => (isActive || pathname == '/sanitationMappingForm' ? "side-nav-active side-nav" : 'side-nav')}>
                  <div className="tooltipPar">
                    <MdCleanHands style={{fontSize: '1.4rem'}}/>
                    <span className="tooltiptextRight">{t(IDS_SanitationMapping)}</span>
                  </div>
                </NavLink>
              </span>
            }

            {((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg == true) && 
              !bShowMenuBasedOnLoginPathKeyValue
              ) &&
              <span>
                <NavLink to='/mapsonlogin' 
                className={({ isActive }) => (isActive ? "side-nav-active side-nav" : 'side-nav')}>

                  <div className="tooltipPar">
                    <FaMapLocationDot style={{fontSize: '1.2rem'}}/>
                    <span className="tooltiptextRight">{t(IDS_TrackToilet)}</span>
                  </div>
                </NavLink>
              </span>  
            } 
          </div>

          {(!bShowMenuBasedOnTracedDevcsKeyValue) && 
            <div className= "flex flex-col gap-2 flex-center"
            // onClick={this.switchMenu()}
            >
              {/* {this.props.calledFromMap && <TopNav fromSideNav={true}/>} */}

              
              <span className="">
                  <NavLink to="/paramDocumentation"  
                className={({ isActive }) => (isActive || pathname == '/paramInfo' ? "side-nav-active side-nav" : 'side-nav')}>
                <div className="tooltipPar">
                      <TiDocumentText/>
                        <span className="tooltiptextRight">{t(IDS_ParamDocumentation)}</span>
                    </div>
                  </NavLink> 
              </span>


              <span className="">
                  <NavLink to="/support"  
                    className={({ isActive }) => (isActive ? "side-nav-active side-nav" : 'side-nav')}>
                    <div className="tooltipPar">
                      <RiCustomerService2Fill/>
                        <span className="tooltiptextRight">{t(IDS_Support)}</span>
                    </div>
                  </NavLink> 
              </span>

              <span className="">
                  <NavLink to="/faqs"  
                    className={({ isActive }) => (isActive ? "side-nav-active side-nav" : 'side-nav')}>
                    <div className="tooltipPar">
                        <FaQuestion/>
                        <span className="tooltiptextRight">{t(IDS_FAQs)}</span>
                    </div>
                  </NavLink>
              </span>

              <div className='w-full flex-center' style={{display : (!bShowMenuBasedOnTracedDevcsKeyValue) ? "block" : "none" }}>
                <ButtonToolbar className='flex-center w-full' style={{flexWrap:"nowrap", justifyContent:"center"}}>
                  <OverlayTrigger 
                    trigger = "click" 
                    rootClose = {this.state.showRootClose}
                    placement = {"right"}
                    
                  
                    overlay = {
                      <Popover className = "userProfileFormPopover " style={{zIndex: 1040}}>
                        
                        <div className="container col-lg-12 col-lg-offset-1
                                                  col-md-12 col-md-offset-2"                 
                            style={{padding: "0rem"}}
                        >
                          <div className="modal-body p-4 box" style={{margin: "0rem"}} > 
                            <form hidden={this.state.showEditForm == true}>
                          
                              <div className=" reg-form" style={{display: "flex"}}>
                                <div style={{flex: "1"}} className="userProfileHeading">{t(IDS_UserProfile)}</div>                      
                              </div>

                              <div className="reg-form">
                                <div  style={{display: "flex"}}>
                                  <label className="userProfileFormLabel" style={{flex: "1"}}> {t(IDS_Name)}:</label>
                                  <label className="userProfileFormLabel" style={{flex: "1", color:"#404040", fontSize:""}}>{this.state.firstName} {this.state.lastName}</label>
                                </div>
                              </div>

                              <div className="reg-form">
                                <div  style={{display: "flex"}}>
                                  <label className="userProfileFormLabel" style={{flex: "1"}}>  {t(IDS_EmailID)}:</label>
                                  <label className="userProfileFormLabel" style={{flex: "1", color: "#404040", fontSize:""}}>{this.state.userID}</label>
                                </div>
                              </div>

                              <div className="reg-form">
                                <div className="inputgroupCustom" style={{display: "flex"}}>
                            
                                  <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_PhoneNo)}:</label>
                                  <label className="userProfileFormLabel" style={{flex: "1", color: "#404040"}}>{this.state.CountryCode} {this.state.userPhoneNo}</label>
                                </div>
                              </div>

                              <div className="reg-form">
                                <div className="inputgroupCustom" style={{display: "flex"}}>
                            
                                  <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_NumberOfOwnDevice)}:</label>
                                  <label className="userProfileFormLabel" style={{flex: "1", color: "#404040"}}>{this.state.ownDeviceCount}</label>
                                </div>
                              </div>

                              <div className="reg-form">
                                <div className="inputgroupCustom" style={{display: "flex"}}>
                  
                                  <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_NumberOfVisbleDevices)}:</label>
                                  <label className="userProfileFormLabel" style={{flex: "1", color: "#404040"}}>{this.state.visibleDeviceCount}</label>
                                </div>
                              </div>
                            
                              <div className = "buttonErrorMessage">
                                {this.state.errors.others.length > 0 && 
                                  <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>}
                              </div>

                              <div className="reg-form"> 
                              {bShowMenuBasedOnLoginPathKeyValue == false 
                              ?
                                <div className="inputgroupCustom" style={{justifyContent: "center"}}>
                                  <VcSetupDevice invokedFrom = "UserProfilePopOver"
                                    toggleRootCloseValueBasedOnSetupDeviceModal = {this.toggleRootCloseValueBasedOnSetupDeviceModal}/>   
                                </div>
                              :
                                null
                              }

                                <div>
                                  <a href="#" className="textDecoNone inputgroupCustom" style={{color:"#043e73", fontSize:"1rem", paddingTop: "0.5rem", backgroundColor: "transparent", border:"none", justifyContent: "center"}} onClick={this.onClickChangePassword}>
                                    {t(IDS_ChangePassword)}
                                  </a> 
                                </div>
                              </div>

                              <div style={{display:"flex", fontSize:"0.9rem", justifyContent:"space-evenly"}}>
                                <button type="button" onClick= {this.onEditButtonclick } className="btn-lg logbtn buttonStyle" >{t(IDS_Edit)}</button>                        
                                <button type="button" onClick= {this.onCloseButtonclick} className="btn-lg logbtn buttonStyle" >{t(IDS_Close)}</button>                        
                              </div>

                          </form>

                          <form hidden={this.state.showEditForm == false}>
                              <div className=" reg-form" style={{display: "flex"}}>
                                <div style={{flex: "1"}} className="userProfileHeading">
                                  {this.state.showEditProfileFields == "ChangePassword" ? t(IDS_ChangeUserPassword) : t(IDS_EditUserProfile)}
                                </div>                      
                              </div>

                              {this.state.showEditProfileFields == "ChangePassword" ?
                                <div>
                                  <div className="reg-form">
                                    <div  style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_OldPass)}:</label>
                                      <input type={this.state.passwordShown == true ? "text" : "password"} 
                                        className="userProfileFormLabel" 
                                        name='oldPassword'
                                        value = {this.state.oldPassword} 
                                        onChange = {this.onChangeOldPassword} noValidate
                                      />
                                    </div>
                                  </div>

                                  <div className="reg-form">
                                    <div  style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_NewPass)}:</label>
                                      <input autoComplete="off" type={this.state.passwordShown == true ? "text" : "password"} 
                                        className="userProfileFormLabel" 
                                        name='newPassword'
                                        value = {this.state.newPassword} 
                                        onChange = {this.onChnageNewPassword} noValidate  
                                      />                            
                                    </div>
                                  </div>

                                  <div className="reg-form">
                                    <div  style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_CnfrmPass)}:</label>
                                      <input autoComplete="off" type={this.state.passwordShown == true ? "text" : "password"} 
                                            className="userProfileFormLabel" 
                                            name='cnfrmNewPassword'
                                            value = {this.state.cnfrmNewPassword} 
                                            onChange = {this.onChnageCnfrmNewPassword} noValidate
                                      />                            
                                    </div> 
                                  </div> 
                                  <div className="reg-form">
                                    <div style={{display: "inline"}}>
                                      <input type='checkbox' 
                                            className="userProfileFormLabel" 
                                            onClick= {this.onTogglePassword}
                                            value = {this.state.passwordShown} 
                                      />  
                                      <label style={{flex: "1", marginLeft: "0.4rem"}}>{t(IDS_ShowPassword)}</label>                         
                                    </div> 
                                  </div> 
                                </div>
                              :
                                <div>
                                  <div className="reg-form">
                                    <div  style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_FirstName)}:</label>
                                      <input type='name' 
                                            className="userProfileFormLabel" 
                                            required="required" 
                                            onChange={this.onChnageFisrtName}
                                            // defaultValue={this.state.firstName} 
                                            value={this.state.firstName} 
                                      />
                                    </div>
                                  </div>

                                  <div className="reg-form">
                                    <div  style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_LastName)}:</label>
                                      <input type='name' 
                                            className="userProfileFormLabel" 
                                            required="required" 
                                            onChange={this.onChnageLastName}
                                            // defaultValue={this.state.lastName} 
                                            value={this.state.lastName} 
                                      />                            
                                    </div>
                                  </div>

                                  <div className="reg-form">
                                    <div  style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_EmailID)}:</label>
                                      <input type='name' 
                                            className="userProfileFormLabel" 
                                            required="required" 
                                            value={this.state.userID} 
                                            disabled
                                      />
                                    </div>
                                  </div>

                                  <div className="reg-form">
                                    <div className="inputgroupCustom" style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_PhoneNo)}:</label>
                                      <span style={{display:"flex"}}> 
                                      <select className="userProfileFormLabel"
                                                        style={{width:"4.3rem"}}
                                                        required
                                                        value={this.state.CountryCode}
                                                        onChange={this.onChangeCountryCode}
                                                >
                                                <option value="+91" select= "true">+91</option> 
                                                {(this.state.CountryInfo).map((singleCountryCode, index) => 
                                                <option key={index} value={singleCountryCode["CountryCode"]}>
                                                  {singleCountryCode["CountryCode"]}
                                                  {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                  {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                  {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                  {'\xA0'+singleCountryCode["Country"]}</option>)}

                                        </select>
                                        <input type='name' 
                                            className="userProfileFormLabel" 
                                            required="required" 
                                            onChange={this.onChnagePhoneNo}
                                            defaultValue={this.state.userPhoneNo} 
                                      />

                                      </span>

                                    </div>
                                  </div>

                                  <div className="reg-form">
                                    <div className="inputgroupCustom" style={{display: "flex"}}>
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_NumberOfOwnDevice)}:</label>
                                      <input type='name' 
                                            className="userProfileFormLabel" 
                                            required="required" 
                                            defaultValue={this.state.ownDeviceCount} 
                                            disabled
                                      />
                                    </div>
                                  </div>

                                  <div className="reg-form">
                                    <div className="inputgroupCustom" style={{display: "flex"}}>
                  
                                      <label className="userProfileFormLabel" style={{flex: "1"}}>{t(IDS_NumberOfVisbleDevices)}:</label>
                                      <input type='name' 
                                            className="userProfileFormLabel" 
                                            required="required" 
                                            defaultValue={this.state.visibleDeviceCount} 
                                            disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              }
                            
                              <div className = "buttonErrorMessage">
                                {this.state.errors.others.length > 0 && 
                                  <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>}
                              </div>

                              <div style={{display:"flex", fontSize:"0.9rem", justifyContent:"space-evenly"}}>
                                <button type="button" className="btn-lg logbtn" style={{fontSize:"1rem", color:"white", background:"var(--primaryColor)", borderRadius: "5px", padding: "0.5rem"}}
                                  onClick= {this.state.showEditProfileFields == "ChangePassword" ? this.changeUserPassword : this.onSaveButtonclick} 
                                >
                                  {t(IDS_Save)}
                                </button>                        
                                <button type="submit" className="btn-lg logbtn" style={{fontSize:"1rem", color:"white", background:"var(--primaryColor)", borderRadius: "5px", padding: "0.5rem"}}
                                  onClick= {this.onEditCloseButtonclick}
                                >
                                  {t(IDS_Close)}
                                </button>                        
                              </div>
                          </form>
                          </div>                   
                        </div> 
                      </Popover>
                    }
                  >   
                    <div onClick={this.getLoggedInUserInfo} className='flex-center side-nav c-pointer'> 
                      <div className="tooltipPar">
                        <FaUser/>
                        <span className="tooltiptextRight">{t(IDS_UserProfile)}</span>
                    </div>
                    </div>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> 

              {(!bShowMenuBasedOnTracedDevcsKeyValue) && 
              <span className='c-pointer' style={{display:"inline"}}>
                {this.renderRedirect()}
                  <span onClick = { () => this.onLogOut("LogoutButton")} className="side-nav " >
                  <div className="tooltipPar"  >
                        <FaSignOutAlt/>
                        <span className="tooltiptextRight">{t(IDS_Logout)}</span>
                    </div>
                  </span> 
              </span>
              }

              {this.state.objPrivilege != null && 
                (
                  ((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg) && (menuSwitchBtn != "CrmMenu") ||
                  (bShowMenuBasedOnTracedDevcsKeyValue)) && (!bShowMenuBasedOnLoginPathKeyValue) && !pathname.includes('/sanitationMappingForm')
                ) &&
                <div className="d-flex justify-content-center ">
                      <select className="rounded " style={{backgroundColor: "#F5F5F5", margin: "0.2rem", padding: "0.1rem" , border: "none", cursor: "pointer"}} onChange={this.onChangeLanguageToViewIn} value={languageToViewIn}>
                        <option value={APPLANG_EN}>En</option>
                        <option value={APPLANG_MR}>म</option>
                        <option value={APPLANG_HI}>हिं</option>
                      </select>
                </div>
                } 
                  

            </div>  
          }

      </div>

      </div>
      
    );
  }
}

// Map redux-store state to props function
const mapStateToProps = (state) => ({
  nodeList: state?.treeNodeList?.nodeList,
  deviceList: state?.deviceList?.devices
});

// Map dispatch-actions function to props function
const mapDispatchToProps = (dispatch) => ({
  clearDeviceList: () => dispatch(clearDeviceList()),
  clearTreeNodeList: () => dispatch(clearTreeNodeList())
});

VcSideNav.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default connect(mapStateToProps, mapDispatchToProps)(VcSideNav);