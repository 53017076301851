import React, { Component } from 'react';
import logoviliso from '../IMAGES/vilisoLogo.png';
import '../CSS/Logintest.css';
import { Link } from "react-router-dom";
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppRelevantDataContext } from '../../AppContext';
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
import { IDS_Email, IDS_Password, IDS_SignIn, IDS_ForgotPassword, IDS_TrackOdourLevelOfToilets, IDS_NotAUserYet,
        IDS_LoginUserNotRegist, IDS_LoginPassInvalid,IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_LoginEmailError, IDS_RegistPasswordLengthError,
        APPLANG_MR, APPLANG_EN, APPLANG_HI, IDS_MaxSessExceeded, IDS_MaxSessExceededLogOutLastSess, IDS_ProvideToiletFeedbackRating, 
        IDS_AlertMsgProvideToiletFeedbackRating, IDS_RegistInvalidatedUserOrBlockUser, IDS_AirQualityMap, IDS_EnterFirstName, IDS_FirstName, IDS_LastName, 
        IDS_EnterNameToContinue, IDS_Cancel, IDS_Continue, IDS_SignInWithGoogle, IDS_SignInWithApple, IDS_DownloadUserManual, IDS_KnowAboutHHM, IDS_TrackOdourLevel, IDS_About, IDS_Help, IDS_ConnectWithUs, IDS_UserManual, IDS_AboutHHMBtn,
        IDS_RateToilets, IDS_OurProducts, IDS_WelcomeToSmartHHM, IDS_MonitorHealthHygienewithUs, IDS_OR } from '../../VcLanguage';
import { APPLE_AUTH, CLIENT_TYPE, GOOGLE_AUTH, SMART_HHM_AUTH } from '../../VcConstants';
import { signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { _auth } from '../../VcFirebase';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import VcSetupDevice from './VcSetupDevice';
import vilisoLogoTransparent from '../IMAGES/vilisoLogoTransparent.png';
import { FaBookOpen, FaPlayCircle, FaStar } from "react-icons/fa";
import GandhvedhDvcImg from '../IMAGES/GandhvedhDvcImg.jpeg';
import Vayuvedh from '../IMAGES/Vayuvedh.jpeg';
import STLIImg from '../IMAGES/STLIImg.jpeg';
import PFCImg from '../IMAGES/PFCImg.jpeg';
import waterTank from '../IMAGES/waterTank.png';
import { Button, Divider, Flex, Tooltip} from 'antd';
import { Carousel } from 'antd';
import GoogleLogo from '../IMAGES/GoogleLogo.png';
import AppleLogo from '../IMAGES/AppleLogo.webp';

const AppleProvider = new OAuthProvider('apple.com');
const GoogleProvider = new GoogleAuthProvider();

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

class VcLogin extends Component {
	constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      email: '',
      password: null,
      nameModal: false,
      backdrop: 'static',
      passwordShown: false,
      firstName: '',
      lastName: '',
      userInfo: {},
      playYoutubeVideo: false,
      // objLanguageToViewIn: APP_TRANSLATIONS[APPLANG_EN],
      errors: {
        email: '',
        password: '',
        firstName: '',
        others: ''        
      },
      // languageToViewIn:''
    };
    // this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this);

  }

  componentDidMount () {
      let appRelevantDataContextValue = this.context;

      // To get Info from LS which have persisted even after logout 
      // (for eg. Language and Last Loggedin UserID).
      appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);

      // If the Login page is invoked from CMS Provide solution mail link and we get the "/login/provideSolForCustComplaint" in 
      // the URL Path than we will save that url path and next page url path after login in to context.
      // We are using this context to hide navbar and feature and only show provide solution feature for logged in user.
      // If the Login page is invoking from some of the deiffrent path than we can add that path in context same as belllow code.
      if(window.location.pathname.includes("/login/provideSolForCustComplaint") == true ) {

        // Spliting the login path and getting the path to show next page after login.
        let showNextPagePathAfterLogin = window.location.pathname.split('/').slice(2).join('/');

        // Saving the Invoked Login Page Path and Next page path after login in to the App Context.
        appRelevantDataContextValue.onInvokedloginFromDiffrentPath(window.location.pathname, "/" + showNextPagePathAfterLogin);

      } else {
        // If the Login page is not invoked from the above URL path or If login page is invoked from the Default "smartHHM Login" path
        // than Save this context value for the "onInvokedloginFromDiffrentPath" to empty.
        // We are emptying the context after logout as well.
        appRelevantDataContextValue.onInvokedloginFromDiffrentPath("", "");
      }
  }

  componentDidUpdate () {
    let appRelevantDataContextValue = this.context;
    let modifiedState = this.state;

    // To get Last Loggedin UserID from LS which had persisted even after logout.
    // Update the UserID in the state from the context only if UserID field on screen is empty.
    // (The finally typed UserID from the screen will be saved in appContext on submit).
    if( (modifiedState.email == null || modifiedState.email.length <= 0) && 
         modifiedState.email != appRelevantDataContextValue.loggedInUserInfo.userID
    ) {

      modifiedState.email = appRelevantDataContextValue.loggedInUserInfo.userID;
      this.setState(modifiedState);
    }

  }

  
  openAuthRegisterAndLogin = (UserInfo) => {
    let modifiedState = this.state;
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    let UserID =  UserInfo.email.trim();
    UserID = UserID.toLowerCase();

    axios.post( `${getAPIHostURL()}/handler/openAuthUserRegistAndLogin`, {...UserInfo }).then(response => {
      if(response.data.code == 'SUCCESS') {
        const resData = response.data;
        this.props.handleSuccessfulAuth( [resData.UserID, resData.RetrievedFirstName, resData.RetrievedLastName, "invokedFromAfterLogin"], resData );
        // On successful login, we are saving sessJankari into the context and Local Storage. TODO: uncomment below line for validating API calls.
        appRelevantDataContextValue.onChangeSessJankari(resData.EncChallan, resData.ChaabiToEnc, resData.SessIdToStore);

      } else {
        if (response.data.code == 'RESTRICTED_OR_INVALIDATE_USER') {
          modifiedState.errors.others = t(IDS_RegistInvalidatedUserOrBlockUser, UserID);
        } else if (response.data.code == 'SQL_ERROR') {
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else if (response.data.code == 'MAX_SESS_EXCEEDS') {

          let strMsg = t(IDS_MaxSessExceededLogOutLastSess, UserID);

          if(window.confirm(strMsg)) {
            UserInfo.bEndDateLastUsedSess = true;
            axios.post( `${getAPIHostURL()}/handler/openAuthUserRegistAndLogin`, {...UserInfo } )
            .then(response => {
              if(response.data.code == 'SUCCESS') {
                const resData = response.data;
                this.props.handleSuccessfulAuth( [resData.UserID, resData.RetrievedFirstName, resData.RetrievedLastName, "invokedFromAfterLogin"], resData );
                // On successful login, we are saving sessJankari into the context and Local Storage. TODO: uncomment below line for validating API calls.
                appRelevantDataContextValue.onChangeSessJankari(resData.EncChallan, resData.ChaabiToEnc, resData.SessIdToStore);
              } else {
                if (response.data.code == 'RESTRICTED_OR_INVALIDATE_USER') {
                  modifiedState.errors.others = t(IDS_RegistInvalidatedUserOrBlockUser, UserID);
                } else if (response.data.code == 'MAX_SESS_EXCEEDS') {
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else if (response.data.code == 'SQL_ERROR') {
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);
                return;
              }
            })
            .catch( error => {
              console.log("Network error:");
              modifiedState.errors.others = t(IDS_RegistNetworkError);
              this.setState(modifiedState);
              return;
            }); 
          } else {
            modifiedState.errors.others = t(IDS_MaxSessExceeded, UserID);
          }
        } else {
          console.log("Should not reach here");
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
        // Display the specific error message for login failure
        this.setState(modifiedState);
      }
    })
    .catch( error => {
      console.log("Network error:", error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError);
      this.setState(modifiedState);
    }); 
  }

  onLoginSumbit = (e) => {

    e.preventDefault();

    // First get the full state
    let modifiedState = this.state;

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

     // Convert UserID to lowercase and remove all whiteSpaces before sending to Server as it makes comparison easy and valid.
     let UserID =  modifiedState.email.trim();
     UserID = UserID.toLowerCase();
 
    let clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const loginData = {
      email: UserID,
      password: modifiedState.password,
      clientTimeZone: clientTimeZone,
      clientType: CLIENT_TYPE,
      bEndDateLastUsedSess: false,
      authType: SMART_HHM_AUTH
    }
    
    axios.post( `${getAPIHostURL()}/handler/login`, { loginData } )
    .then(response => {

      if(response.data.code == 'SUCCESS') {

        const resData = response.data;
        this.props.handleSuccessfulAuth( [resData.UserID, resData.RetrievedFirstName, resData.RetrievedLastName, "invokedFromAfterLogin"], resData );

        // On successful login, we are saving sessJankari into the context and Local Storage. TODO: uncomment below line for validating API calls.
        appRelevantDataContextValue.onChangeSessJankari(resData.EncChallan, resData.ChaabiToEnc, resData.SessIdToStore);

      } else {
        if (response.data.code == 'RESTRICTED_OR_INVALIDATE_USER') {
          modifiedState.errors.others = t(IDS_RegistInvalidatedUserOrBlockUser, UserID);
        } else if (response.data.code == 'USER_NOT_REGISTERED') {
          // Let the user know that he is not registered yet
          modifiedState.errors.others = t(IDS_LoginUserNotRegist);
        } else if (response.data.code == 'USER_PASSWORD_INVALID') {
          // Tell the user that his password is invalid
          modifiedState.errors.others = t(IDS_LoginPassInvalid);
        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else if (response.data.code == 'MAX_SESS_EXCEEDS') {
          
          let strMsg = t(IDS_MaxSessExceededLogOutLastSess, UserID);
          
          if(window.confirm(strMsg)) {

            // Here we are EndDating last used session of the user and creating a new session for the user.
            loginData.bEndDateLastUsedSess = true;

            axios.post( `${getAPIHostURL()}/handler/login`, { loginData } )
            .then(response => {
              if(response.data.code == 'SUCCESS') {

                const resData = response.data;
                this.props.handleSuccessfulAuth( [resData.UserID, resData.RetrievedFirstName, resData.RetrievedLastName, "invokedFromAfterLogin"], resData );
                
                // On successful login, we are saving sessJankari into the context and Local Storage. TODO: uncomment below line for validating API calls.
                appRelevantDataContextValue.onChangeSessJankari(resData.EncChallan, resData.ChaabiToEnc, resData.SessIdToStore);
        
              } else {
                if (response.data.code == 'RESTRICTED_OR_INVALIDATE_USER') {
                  modifiedState.errors.others = t(IDS_RegistInvalidatedUserOrBlockUser, UserID);
                } else if (response.data.code == 'USER_NOT_REGISTERED') {
                  // Let the user know that he is not registered yet
                  modifiedState.errors.others = t(IDS_LoginUserNotRegist);
                } else if (response.data.code == 'MAX_SESS_EXCEEDS') {

                  modifiedState.errors.others = t(IDS_LoginServerIssue);

                } else if (response.data.code == 'USER_PASSWORD_INVALID') {
                  // Tell the user that his password is invalid
                  modifiedState.errors.others = t(IDS_LoginPassInvalid);
                } else if (response.data.code == 'SQL_ERROR') {
                  // Tell the user that Server is experiencing errors
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);
                return;
              }
            })
            .catch( error => {
              console.log("Network error:");
              console.log(error);
              // Tell the user that there are network issues
              modifiedState.errors.others = t(IDS_RegistNetworkError);
              this.setState(modifiedState);
              return;
            }); 

          } else {
            modifiedState.errors.others = t(IDS_MaxSessExceeded, UserID);
          }
        } else {
          console.log("Should not reach here");
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
        // Display the specific error message for login failure
        this.setState(modifiedState);
      }

    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError);
      this.setState(modifiedState);
    }); 
  }

  signInWithGoogle = (e) => {

    e.preventDefault();
    signInWithPopup(_auth, GoogleProvider).then((result) => {
      const tokenRes = result._tokenResponse;
      const {email, firstName, lastName, localId } = tokenRes;
      let clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
      let UserInfo = {
        email,
        firstName,
        lastName,
        clientTimeZone: clientTimeZone,
        clientType: CLIENT_TYPE,
        oAuthUid : localId,
        isHiddenOAuthEmail : false,
        bEndDateLastUsedSess: false,
        authType: GOOGLE_AUTH
        
      }

      this.openAuthRegisterAndLogin(UserInfo);
      
    }).catch((error) => {
      //Google login failed 
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  };

  oAuthSignInApple = (firstName = null) => {
    
    let {email,displayName, uid, isAnonymous} = this.state.userInfo;
    let lastName = '';
    firstName = displayName ? displayName.split(' ')[0] : firstName ? firstName : this.state.firstName;
    lastName = displayName ? displayName.split(' ')[1] : this.state.lastName;
    
    let clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let UserInfo = {
      email,
      firstName,
      lastName,
      clientTimeZone: clientTimeZone,
      clientType: CLIENT_TYPE,
      oAuthUid : uid,
      isHiddenOAuthEmail : isAnonymous,
      bEndDateLastUsedSess: false,
      authType: APPLE_AUTH,
    }
 
    this.openAuthRegisterAndLogin(UserInfo);
  }

  checkNamePresentThenSignIn = (userInfo) => {
    
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    
    const { displayName } = userInfo;
    if(displayName){
      this.oAuthSignInApple();
     } else {
      let userDetails = {"Email": userInfo.email};
      let modifiedState = this.state;
      
      axios.post( `${getAPIHostURL()}/wclient/getUserFirstName`, {...userDetails } )
      .then(response => {
        if(response.data.code == 'SUCCESS') {
          // console.log(response.data.firstName); 
          if(response.data.firstName == null && response.data.firstName.length > 0 ){
            this.oAuthSignInApple(response.data.firstName);
          } else {
            modifiedState.nameModal = true;
            this.setState({...modifiedState})
            return
          }
        } else{
          if (response.data.code == 'SQL_ERROR') {
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
            console.log("Should not reach here.")
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          
          this.setState(modifiedState);
          return;

        }
      }).catch( error => {
        console.log("Network error:");
        console.log(error);
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      }); 
     }
    }
  

  //Sign In with Firebase OAuth gives userInfo which is stored in state
  //In Apple signin display name may not be present then we will check and 
  //either open form to get name or do sign in directly
  signInWithApple = (e) => {
    e.preventDefault();
    signInWithPopup(_auth, AppleProvider)
    .then((result) => {
      const userInfo = result.user;
      let modifiedState = this.state;
      modifiedState.userInfo = userInfo;
      this.setState(modifiedState);
      this.checkNamePresentThenSignIn(userInfo);
    }).catch((error) => {
      //Failed to login with apple provider
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  };

  handleChange = (event) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = this.state;    
    modifiedState.errors.others = "";

    const { name, value } = event.target;
  
    switch (name) {
      case 'email': 
        modifiedState.errors.email =  value.length > 0 ? ((validEmailRegex.test(value)) ? '' : t(IDS_LoginEmailError)) : "";
        modifiedState.email = value;
        break;
      case 'password': 
        modifiedState.errors.password = ((value.length < 1 ) || (value.length > 7)) ? "" : t(IDS_RegistPasswordLengthError);
        modifiedState.password = value;
        break;
      case 'firstName': 
        modifiedState.errors.firstName = (value.length > 0 ) ? "" : t(IDS_EnterFirstName);
        modifiedState.firstName = value;
        break;
      case 'lastName': 
        modifiedState.lastName = value;
        break;
      default:
        break;
    }  
    this.setState(modifiedState);
  }


  toggle = (e) => {
    let modifiedState = this.state;
    modifiedState.nameModal = !this.state.nameModal;
    modifiedState.lastName = '';
    modifiedState.firstName = '';
    modifiedState.errors.firstName = '';
    this.setState(modifiedState);
  }

  appleSignInWithName = (e) => {
    e.preventDefault();
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    if(this.state.firstName == null || this.state.firstName.length <= 0){
    this.setState({...this.state, errors:{...this.state.errors, firstName:t(IDS_EnterFirstName)}})
    }else{
      this.oAuthSignInApple();
    }
  }

  onChangeLanguageToViewIn = (e) => {
    let strLanguageToViewIn = e.target.value;
    let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

    // Save into the App Context the newly selected language.
    appRelevantDataContextValue.onChangeLanguageToViewIn(strLanguageToViewIn);
  }

  onTogglePassword = () => {
    let modifiedState = this.state;
    modifiedState.passwordShown = !modifiedState.passwordShown;
    this.setState(modifiedState);
  }

  onclickPlayButton = () => {

    let modifiedState = this.state;
    modifiedState.playYoutubeVideo = !modifiedState.playYoutubeVideo;
    this.setState(modifiedState);
  }
	
  render() {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

    // Getting the Context value for the invokedLoginPgInfo, loginPagePath and showNextPagePathAfterLogin.
    let invokedLoginPgInfo = appRelevantDataContextValue.invokedLoginPgInfo;
    let loginPagePath = invokedLoginPgInfo.loginPagePath;
    let showNextPagePathAfterLogin = invokedLoginPgInfo.showNextPagePathAfterLogin;

    let bHideBasedOnLoginPathKeyValue =  ((invokedLoginPgInfo != null && Object.keys(invokedLoginPgInfo).length > 0) &&
      (loginPagePath != null && loginPagePath.length > 0) && (showNextPagePathAfterLogin != null 
        && showNextPagePathAfterLogin.length > 0)) ? true : false;

    const {errors} = this.state;

    const color = {
      color : "var(--errorColor)",
      fontSize:"13px",
    }
    
    return (
      <div class="container-fluid" style={{height: "100vh"}}>
        <div class="row full-height">
          <div class="col-xl-7 col-lg-7 col-md-7 order-xl-1 order-lg-1 order-md-1 order-sm-2 order-2 MenuBoxCol pe-0 d-none d-md-block">
            <div class="d-flex h-100 MenuBoxRow">
              <div class="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 d-flex flex-column justify-content-between MenuBox" >
                <div className="row HeaderMenu" >
                  <div className="col-xl-8 col-lg-7 col-md-12 col-sm-8 col-8">
                    <div class="d-flex justify-content-start" gap="small" wrap="wrap">
                      <Button type="text" onClick={() => window.open('https://viliso.in/about-us/', '_blank')} style={{ fontSize: "1rem", color: "white", fontWeight: "700" }}>{t(IDS_About)}</Button>
                      <Button type="text" onClick={() => window.open('https://viliso.in/help-center/', '_blank')} style={{ fontSize: "1rem", color: "white", fontWeight: "700" }}>{t(IDS_Help)}</Button>
                      <Button type="text" onClick={() => window.open('https://viliso.in/free-demo/', '_blank')} style={{ fontSize: "1rem", color: "white", fontWeight: "700" }}>{t(IDS_ConnectWithUs)}</Button>
                    </div>
                  </div>
                  {/* <div className="col-xl-4 col-lg-5 col-md-12 col-sm-4 col-4 d-flex justify-content-between"> */}
                  <div className="col-xl-4 col-lg-5 col-md-12 col-sm-4 col-4 d-flex justify-content-end">
                    <Link style={{textDecoration: "none"}} to ="/maps">
                      <Button type="text" className="rounded" size="small" style={{fontSize: "0.9rem", backgroundColor: "rgba(255, 255, 255, 0.5)", color:"black", fontWeight: "500", marginTop: "0.3rem", marginRight: "0.4rem"}} hidden = {bHideBasedOnLoginPathKeyValue == true}>
                        <Tooltip title={t(IDS_TrackOdourLevelOfToilets)}>
                          <span className='loginMapBtn'>{t(IDS_TrackOdourLevel)}</span>
                        </Tooltip>
                      </Button>
                    </Link>
                    
                    <select class="rounded" style={{fontSize: "0.9rem", backgroundColor: "rgba(255, 255, 255, 0.5)", margin: "0.3rem", padding: "0.1rem", paddingTop:"0.2rem", border: "none", cursor: "pointer" }} onChange={this.onChangeLanguageToViewIn} value={languageToViewIn}  hidden = {bHideBasedOnLoginPathKeyValue == true}>
                      <option value={APPLANG_EN}>En</option>
                      <option value={APPLANG_MR}>म</option>
                      <option value={APPLANG_HI}>हिं</option>
                    </select>
                  </div>
                </div>

                <div className="row justify-content-center align-items-center Products" >
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h4 class="pb-4 fw-bold" style={{textShadow: "2px 2px 5px rgba(255, 255, 255, 0.9)"}}>{t(IDS_OurProducts)}</h4>
                    <Carousel autoplay autoplaySpeed={4000} className="Carousel">
                      <div class="p-4" style={{ backgroundColor: "#E6E6FA"}}>
                        <div class="row d-flex justify-content-center p-2 CorouselContainer" >
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox">
                            <img
                              src={GandhvedhDvcImg}
                              alt="Button Image"
                              className="DvcImg"
                            />
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" >
                            <div>
                              <p className='DvcName'>Gandhvedh<sup>®</sup> </p>
                              <p className='DvcDescription'>Monitors Malodour, TVOC, Humidity and Temperature in Toilets.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="p-4" style={{ backgroundColor: "#E6E6FA"}}>
                        <div class="row d-flex justify-content-center p-2 CorouselContainer" >
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" >
                            <img
                              src={Vayuvedh}
                              alt="Button Image"
                              className="DvcImg"
                            />
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" >
                            <div>
                              <p className='DvcName'>Vayuvedh<sup>®</sup> </p>
                              <p className='DvcDescription'>Monitors harmful gas and particulate matter in air.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="p-4" style={{ backgroundColor: "#E6E6FA"}}>
                        <div class="row d-flex justify-content-center p-2 CorouselContainer" >
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" style={{backgroundColor: ""}}>
                            <img
                              src={PFCImg}
                              alt="Button Image"
                              className="DvcImg"
                              style={{borderRadius: "10%", width: "105%", height: "90%"}}
                            />
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" >
                            <div>
                              <p className='DvcName'>People Counter</p>
                              <p className='DvcDescription'>Tracks the number of people visiting toilets.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="p-4" style={{ backgroundColor: "#E6E6FA"}}>
                        <div class="row d-flex justify-content-center p-2 CorouselContainer" >
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" style={{backgroundColor: ""}}>
                            <img
                              src={waterTank}
                              alt="Button Image"
                              className="DvcImg"
                            />
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" >
                            <div>
                              <p className='DvcName'>Water Level Indicator</p>
                              <p className='DvcDescription'>Monitors water level in the water tank.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="p-4" style={{ backgroundColor: "#E6E6FA"}}>
                        <div class="row d-flex justify-content-center p-2 CorouselContainer" >
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" >
                            <img
                              src={STLIImg}
                              alt="Button Image"
                              className="DvcImg"
                              style={{borderRadius: "10%", width: "100%", height: "90%"}}
                            />
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-flex justify-content-center align-items-center ImgAndDvcBox" >
                            <div>
                              <p className='DvcName'>Septic Tank Level Indicator</p>
                              <p className='DvcDescription'>Monitors sewage level in the septic tank.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel>
                  </div>
                </div>

                <div class="row justify-content-start align-items-bottom pb-1 footerMenu">
                  <Flex gap="small" wrap="wrap">
                    <Tooltip title={t(IDS_DownloadUserManual)}>
                      <Button type="text" onClick={() => window.open('https://viliso.in/wp-content/uploads/2021/09/SmartHHMWebApplicationUserGuide_v1.1.pdf', '_blank')} style={{fontSize: "1rem", color:"white", fontWeight: "500"}}>
                        <FaBookOpen style={{fontSize: "1.5rem", marginRight: '5px'}} /> {t(IDS_UserManual)}
                      </Button>
                    </Tooltip>

                    <Tooltip title={t(IDS_KnowAboutHHM)}>
                      <Button type="text" onClick={this.onclickPlayButton} style={{fontSize: "1rem", color:"white", fontWeight: "500"}}
                        icon={<FaPlayCircle  style={{marginBottom: "0.3rem", fontSize: "1.5rem", color: "white"}}/>}
                        >{t(IDS_AboutHHMBtn)}
                      </Button>
                    </Tooltip>

                    <Tooltip title={t(IDS_ProvideToiletFeedbackRating)}>
                      <Link style={{textDecoration: "none"}} to="/maps">
                        <Button type="text" style={{fontSize: "1rem", color:"white", fontWeight: "500"}} 
                          icon={<FaStar  style={{marginBottom: "0.4rem", fontSize: "1.4rem", color: "white"}}/>}  
                          hidden = {bHideBasedOnLoginPathKeyValue == true}
                        >{t(IDS_RateToilets)}
                        </Button>
                      </Link>
                    </Tooltip>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1 ps-0 p-2 LoginBoxCol align-items-center">
            <div class="d-flex h-100 flex-grow-1 LoginBoxDiv" >
              <div class="col-xl-10 col-lg-11 col-md-11 col-sm-9 col-12 d-flex flex-column LoginBox">
                <div>
                <div class="row justify-content-center align-items-center p-0 mt-2">
                  <span className="col-xl-12 col-lg-12 col-md-12">
                    <img src={vilisoLogoTransparent}  className="vilisoLogo" alt="Viliso Logo"/>
                  </span>
                </div>
                <div class="row justify-content-center align-items-top LoginForm" style={{marginTop: "0.5rem", marginBottom: "auto"}}>
                  <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12 p-2 pt-0 d-flex flex-column justify-content-between" style={{padding: "3rem", marginTop: "0.5rem", marginBottom: "auto"}}>
                    <form onSubmit={this.onLoginSumbit} style={{overflowX: "hidden", paddingTop: "0rem", paddingLeft: "0.3rem", paddingRight: "0.3rem", marginTop: "0.3rem", marginBottom: "auto", marginLeft: "1rem", marginRight:"1rem", overflowY: "auto"}}>
                      <div style={{paddingTop: "0.1rem"}}>
                        <h4 class="fw-bold mb-0 welcomeText mt-1" style={{color: bHideBasedOnLoginPathKeyValue == true ? "orange" : "#414142"}}>{ bHideBasedOnLoginPathKeyValue == true ? "Login to provide Solution" : t(IDS_WelcomeToSmartHHM)}</h4>
                        {bHideBasedOnLoginPathKeyValue == false &&
                          <h6 style={{ color: "gray", fontSize: "0.8rem", fontWeight: "510", paddingLeft: "0rem", textAlign: "center" }}>{t(IDS_MonitorHealthHygienewithUs)}</h6>
                        }
                      </div>

                      <div className="pt-1 mb-3" style={{textAlign: "left",paddingTop: "1rem"}}>
                        <label htmlFor="exampleInputEmail1" className="form-label" >{t(IDS_Email)}</label>
                        <input type="email" 
                          style={{ marginTop: "-0.5rem"}}
                          name='email' 
                          required="required" 
                          onChange={this.handleChange}
                          defaultValue={this.state.email} noValidate 
                          className="form-control rounded-pill LoginInput" id="exampleInputEmail1" aria-describedby="emailHelp"
                        />
                        {errors.email.length > 0 && 
                          <span style={color} className='error'>{errors.email}</span>
                        }
                      </div>

                      <div className="mb-3" style={{textAlign: "left"}}>
                        <label htmlFor="exampleInputPassword1" className="form-label">{t(IDS_Password)}</label>

                        <div style={{ position: 'relative' }}>
                          <input
                            id="exampleInputPassword1"
                            autoComplete="off"
                            type={this.state.passwordShown ? 'text' : 'password'}
                            name="password"
                            className="form-control rounded-pill LoginInput"
                            style={{ marginBottom: '0.2rem', marginTop: "-0.5rem", paddingRight: '2.5rem'}}
                            required="required"
                            onChange={this.handleChange}
                            noValidate
                          />
                          <span
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                            }}
                            onClick={this.onTogglePassword}
                          >
                            {this.state.passwordShown ? (
                              <MdVisibilityOff size="1.5rem" className='viewAndEditIcon' style={{ color: '#043e73' }} />
                            ) : (
                              <MdVisibility size="1.5rem" className='viewAndEditIcon' style={{ color: '#043e73' }} />
                            )}
                          </span>
                        </div>  

                        <div style={{textAlign: "right", width: "100%", fontSize: "0.9rem"}}>
                          <Link className="textDecoNone forgot-p" style={{textAlign: "left", marginTop: "-0.2rem"}} to="/forgotpassword">{t(IDS_ForgotPassword)}?</Link>
                        </div>
                        {errors.password.length > 0 && (
                            <span><p style={{color : "var(--errorColor)",fontSize:"13px",marginTop: "-0.3rem"}} className='error'>{errors.password}</p></span>
                          )}


                        
                      </div>

                      <button htmlType="submit" shape="round" size="large" class="loginMapBtn rounded-pill btn-block btn" style={{width: "100%", backgroundColor:"#303597", color: "white", opacity: "0.9"}}>{t(IDS_SignIn)}</button>

                      <div className= "buttonErrorMessage">
                        {errors.others.length > 0 && 
                          <p style={color} className='error'>{errors.others}</p>
                        }
                        
                        {this.props.aStateValFromVcLoginPage.errors.others.length > 0 && 
                          <p style={color} className='error'>{this.props.aStateValFromVcLoginPage.errors.others}</p>
                        }

                      </div>

                      <div class="text-center">
                        <Link to ="/registration" className="textDecoNone loginFooterLinks">{t(IDS_NotAUserYet)}</Link>
                      </div>

                      <Divider style={{borderBlockStartColor:'rgba(0, 0, 0, 0.25)'}}>{t(IDS_OR)}</Divider>

                      <div className="text-center pt-0">
                        <Tooltip title={t(IDS_SignInWithGoogle)}>
                          <Button
                            type="text"
                            shape="circle"
                            size='large'
                            style={{ border: "none", marginRight:"1rem" }}
                            onClick={this.signInWithGoogle}
                          >
                            <img
                              src={GoogleLogo}
                              alt="Button Image"
                              style={{ width: "2rem", height: "2rem", paddingBottom:"0.3rem", paddingLeft: "0.2rem", paddingRight: "0.2rem" }}
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title={t(IDS_SignInWithApple)}>
                          <Button
                            type="text"
                            shape="circle"
                            size='large'
                            style={{ border: "none", marginRight:"1rem" }}
                            onClick={this.signInWithApple}
                          >
                            <img
                              src={AppleLogo}
                              alt="Button Image"
                              style={{ width: "2rem", height: "2rem", paddingBottom:"0.2rem", paddingLeft: "0.1rem", paddingRight: "0.1srem" }}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </form>
                    <div className="d-md-none d-lg-none d-xl-none d-xxl-none" style={{overflowX: "hidden", paddingTop: "0.7rem", paddingLeft: "0.2rem", paddingRight: "0.2rem", marginLeft: "1rem", marginRight:"1rem", overflowY: "auto"}}>
                      {/* Hiding this if the Login page invoked from any different URL path. */}
                      <Link style={{textDecoration: "none"}} className=" d-grid col-12 mx-auto pt-2" to ="/maps">
                        <button hidden = {bHideBasedOnLoginPathKeyValue == true} className="loginMapBtn btn btn-sm rounded-pill" type="button" style={{background: "linear-gradient(90deg, #afd0af, #cfe3cf)", color: '#262626'}}>{t(IDS_TrackOdourLevelOfToilets)}</button>
                      </Link>

                      {/* Hiding this if the Login page invoked from any different URL path. */}
                      <Link style={{textDecoration: "none", marginTop: "0.5rem"}} onClick = { () => alert(t(IDS_AlertMsgProvideToiletFeedbackRating))} className="textDecoNone d-grid col-12 mx-auto" to ="/maps">
                        <button hidden = {bHideBasedOnLoginPathKeyValue == true} className="loginMapBtn btn btn-sm rounded-pill" type="button" style={{background: "linear-gradient(90deg, #afd0af, #cfe3cf)", color: '#262626'}}>{t(IDS_ProvideToiletFeedbackRating)}</button>
                      </Link>

                      {/* <div style={{marginTop: "0.2rem"}} >
                        <VcSetupDevice className="loginFooterLinks" invokedFrom = "Login"/>
                      </div> */}

                      {/* <div style={{marginTop: "0.4rem"}} hidden = {bHideBasedOnLoginPathKeyValue == true}>
                        <Link className = "textDecoNone loginFooterLinks" to ="/airQualityMap">{t(IDS_AirQualityMap)}</Link>
                      </div> */}

                      <div className="d-flex justify-content-center mt-2">
                        <select className="rounded " style={{backgroundColor: "#F5F5F5", margin: "0.2rem", padding: "0.1rem" , border: "none", cursor: "pointer"}} onChange={this.onChangeLanguageToViewIn} value={languageToViewIn} hidden={bHideBasedOnLoginPathKeyValue == true}>
                          <option value={APPLANG_EN}>En</option>
                          <option value={APPLANG_MR}>म</option>
                          <option value={APPLANG_HI}>हिं</option>
                        </select>
                      </div>
                      </div>

                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal toggle={this.toggle} isOpen={this.state.nameModal}>
          <ModalHeader>
                {t(IDS_EnterNameToContinue)}
          </ModalHeader>
          <ModalBody>
            
            <form >
              <div className="form-group addCustForm">
                  <div className="inputgroupCustom">
                      <label className="addCustFormLabelWithRequiredFiled">{t(IDS_FirstName)} <span className="addCustRequiredMarkStar">*</span></label>
                      <div className="addCustInputAndError">
                        <input type="text" className="addCustInputForm" name="firstName" 
                            required
                            value={this.state.firstName}
                            onChange={this.handleChange} 
                        />
                        {errors.firstName.length > 0 && 
                            <span  className='addCustError'>{errors.firstName}</span>}     
                      </div>
                  </div>
              </div>
              <div className="form-group addCustForm">
                  <div className="inputgroupCustom">
                      <label className="addCustFormLabelWithRequiredFiled"> {t(IDS_LastName)}</label>
                      <div className="addCustInputAndError">
                        <input type="text" className="addCustInputForm" name="lastName" 
                            value={this.state.lastName}
                            onChange={this.handleChange} 
                        /> 
                      </div>
                  </div>
              </div>
              <div style={{display: "flex", justifyContent: "space-evenly"}}>
                  <div>
                      <button type="button" className="addCustSavebtn" onClick={this.toggle} style={{pointerEvents: "auto"}}> 
                        {t(IDS_Cancel)}
                      </button>
                  </div>
                  <div>
                      <button type="button" className="addCustSavebtn"  onClick={this.appleSignInWithName}>
                        {t(IDS_Continue)}
                      </button>
                  </div>
              </div>
            </form>
            
          </ModalBody>
        </Modal>

        <Modal size="lg" isOpen={this.state.playYoutubeVideo}>
          <ModalHeader toggle={this.onclickPlayButton}>Know About Health Hygiene Monitor</ModalHeader>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '0.7rem' }}>
            <iframe
              width="92%"
              height="400"
              className="rounded"
              src={"https://www.youtube.com/embed/gf9VgkF0P1A"}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ margin: '1rem' }}
            ></iframe>
          </div>
        </Modal>

      </div>
    )
  }

}

VcLogin.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcLogin;