import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import App from '../../App';
import {IDS_ParamInfo, IDS_LoginServerIssue, IDS_RegistNetworkError,
    IDS_ParamDocDetailsAndRangesHeading,
    IDS_OdourInfo, IDS_TEMPInfo, IDS_HUMInfo, IDS_TVOCInfo, IDS_AQIInfo, IDS_VAQIInfo, IDS_COInfo, IDS_CO2Info, IDS_H2SInfo, IDS_LPGInfo, IDS_MRIInfo, IDS_NO2Info, IDS_O3Info, 
    IDS_PM1Info, IDS_PM10Info, IDS_PM25Info, IDS_VRIInfo, IDS_SMOKEInfo, IDS_SO2Info,
    IDS_GoBackParamList} from '../../VcLanguage';
import { AppRelevantDataContext } from '../../AppContext';
import {PARAM_FOR_SHOWING_IMPACTS, AQI, CAQI, CO, CO2, H2S, HUM, LPG, MRI, NH3OD, NO2, O3, PM1, PM10, PM25, SMOKE, SO2, TEMP, VOC, VRI} from '../../VcConstants';
import '../CSS/ParamDocumentation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import VcSideNav from './DeviceHome/VcSideNav';
import {useNavigate, useLocation} from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { clearDeviceList } from './Store/Slices/deviceList';
import { clearTreeNodeList } from './Store/Slices/treeNodeList';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
import { IoArrowBackCircleSharp } from "react-icons/io5";
import VcDetailsAndRanges from './VcDetailsAndRanges';

function VcParameterDocumentation() {
  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();
  let t = context.t; 
  const dispatch = useDispatch();

    const [fetchState, setState] = useState({
        stdAirQualityRanges: {}, // Easier to access individual parame from JSON object
        arrOfRanges: [],
        arrOfDispRangeValues: [],
        showImpactsOfParam: false,
        errors: {
            others: '',
        },
    });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const location = useLocation();
    const { state } = location;
    const {title, selectedMeasuredParam} = state;

    useEffect(() => {

        let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
        
        if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
          // User login information is not present in appRelevantDataContext.
          // Try to get this value if present in LocalStorage and update the app state (so that
          // re-render will happen). If app context data not present in LocalStorage then
          // show the Login Page
          if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() === false ) {
            // App Relevant Data Context not present in LocalStorage as well.
            navigate('/', { replace: true });
          } else {
            // App Relevant Data Context loaded from LocalStorage at AppLeval.
            // Re-render will happen automatically (no action required).
          }
        }

        getAirQualityRangesAndModelInfo();
    
        dispatch(clearTreeNodeList())
        dispatch(clearDeviceList())
        
    }, []);

    useEffect(() => {
        // getUpdatedParams();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    
    }, []);

    // get air quality ranges to show in Details and Ranges Modal
    const getAirQualityRangesAndModelInfo = () => {
        
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        let modifiedState = fetchState;

        modifiedState.arrOfRanges = [];
        modifiedState.arrOfDispRangeValues = [];

        axios.post(`${getAPIHostURL()}/wclient/getAirQualityRangeAndModelInfo`)
        .then(response => {
            if(response.data.code = "SUCCESS") {

                if(response.data["AirQualityRanges"] == null || response.data["AirQualityRanges"].length <=0
                ) {
                    modifiedState.errors = t(IDS_LoginServerIssue);
                } else {
                    let arrAirQualityRanges = response.data["AirQualityRanges"];
                    let MeasuredParam;
                    let HighLow;

                    try {
                        for(let i=0; i < arrAirQualityRanges.length; i++){
                            let singleAirQualityRangeValues = arrAirQualityRanges[i];
                            MeasuredParam = singleAirQualityRangeValues.MeasuredParam;

                            HighLow = JSON.parse(arrAirQualityRanges[i].RangeValues);
                            let singleRangeValues = {
                                MeasuredParam: MeasuredParam,
                                ...HighLow
                            }
                            modifiedState.stdAirQualityRanges[MeasuredParam] = singleRangeValues;
                        }

                        let arrOfRangesOfSelectedParam = modifiedState.stdAirQualityRanges[selectedMeasuredParam] != null ? 
                            modifiedState.stdAirQualityRanges[selectedMeasuredParam]['DispRngSeq'] : [] ;

                        if(arrOfRangesOfSelectedParam != null && arrOfRangesOfSelectedParam.length > 0){
                            arrOfRangesOfSelectedParam.forEach((childSnapshot) => {
                                modifiedState.arrOfRanges.push(Object.keys(childSnapshot)[0]);
                                modifiedState.arrOfDispRangeValues.push(Object.values(childSnapshot)[0]);
                            });
                        }  
                        
                    } catch (error) {
                        console.log(`Should not happen. The data obtained from server is in invalid JSON format.`);
                        // Skip this device information and move to next
                    }

                    // show Details and Ranges old or new model based on if impacts for those params are present.
                    // PARAM_FOR_SHOWING_IMPACTS contains all the parameters which have impacts.
                    for(let i=0; i<PARAM_FOR_SHOWING_IMPACTS.length; i++) {
              
                        if(PARAM_FOR_SHOWING_IMPACTS[i] == selectedMeasuredParam) {
                            modifiedState.showImpactsOfParam = true;
                            break;           
                        } else {
                            modifiedState.showImpactsOfParam = false;
                        }
                    }

                    setState({...modifiedState});

                }
            } else {
                
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {
            // Tell the user that there are network issues
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        }); 

    }

    const showParamInfo = (selectedMeasuredParam) => {
        switch(selectedMeasuredParam) {
            case AQI:
                return t(IDS_AQIInfo);

            case CAQI:
                return t(IDS_VAQIInfo);

            case CO:
                return t(IDS_COInfo);

            case CO2:
                return t(IDS_CO2Info);

            case H2S:
                return t(IDS_H2SInfo);

            case HUM:
                return t(IDS_HUMInfo);

            case LPG:
                return t(IDS_LPGInfo);

            case MRI:
                return t(IDS_MRIInfo);

            case NH3OD:
                return t(IDS_OdourInfo);

            case NO2:
                return t(IDS_NO2Info);

            case O3:
                return t(IDS_O3Info);

            case PM1:
                return t(IDS_PM1Info);

            case PM10:
                return t(IDS_PM10Info);

            case PM25:
                return t(IDS_PM25Info);

            case SMOKE:
                return t(IDS_SMOKEInfo);

            case SO2:
                return t(IDS_SO2Info);

            case TEMP:
                return t(IDS_TEMPInfo);

            case VOC:
                return t(IDS_TVOCInfo);

            case VRI:
                return t(IDS_VRIInfo);
        
            default:
                break;
        }

    }

    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
        // User login information is not present in appRelevantDataContext.
  
        // Next re-render will happen automatically when the AppState is updated again 
        // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
        return App.renderLoadingPage();
    }

    const renderParamInfoPage = () => {
        return (
            <div class = "stickPageToTop">
                <div class="page-header theme-bg-dark py-2 text-center position-relative" style = {{backgroundColor:"#C1E1C1"}}>
                    <div class="container">
                        <div style={{color: "Black", textAlign: "left", display: "flex", alignItems: "center"}}>
                            <span style = {{alignItems: "left", fontSize: "2rem", cursor: "pointer"}} className="tooltipPar">
                                <IoArrowBackCircleSharp onClick={() => navigate('/paramDocumentation')}/>
                                <span className="tooltiptextRight" style={{left: "100%", paddingLeft: "0.2rem", paddingRight: "0.2rem"}}>{t(IDS_GoBackParamList)}</span>
                            </span>
                            <h1 class="page-heading single-col-max mx-auto headingOf2ndPage">{t(IDS_ParamDocDetailsAndRangesHeading, t(title))}</h1>
                        </div>
                    </div>
                </div>

                <section class="informationSection" style = {{paddingBottom: "0rem"}}>
                    <div class="containerForContent" 
                    dangerouslySetInnerHTML={{__html: showParamInfo(selectedMeasuredParam)}}
                    />
                </section>
                <section hidden = {fetchState.arrOfRanges.length <= 0 ||  fetchState.arrOfDispRangeValues <= 0} class="cid-t71qCLrmUU">
                    <div class="containerForContent">
                        <div class="cardForParamDescription customizedCol12">
                            <div class="card-box">
                                <div class="cardHeadForDetailsAndRanges">
                                    <h5 style={{marginBottom: "1.5rem"}} class="card-title display-5 card-title-text titleText"><strong>{t(IDS_ParamInfo, t(title))}</strong></h5>
                                    <VcDetailsAndRanges arrOfRanges={fetchState.arrOfRanges} arrOfDispRangeValues={fetchState.arrOfDispRangeValues} selectedParamToViewRangeDetails={selectedMeasuredParam}
                                        showImpactsOfParam={fetchState.showImpactsOfParam}
                                        invokedFrom={"paramDoc"}
                                    />   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    if(windowWidth < 570) {
        return  < >  
          <div  className={`drawerDiv ${fetchState.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
              <VcSideNav />
              <FaArrowAltCircleLeft 
                  className="closingArrow top-nav" onClick={()=> setState({...fetchState , showDrawer : false})}
              />
          </div>
          <div className='bg-white shadow flex items-center justify-between px-2 py-1 m-1 ' style={{position:"sticky", top:"0", borderRadius:"0.75rem"}}>
              <div className='' style={{width:"60px"}}>
                <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
              </div>
              {fetchState.showDrawer ? 
              <MdClose classname='transition-all' onClick={()=> setState({...fetchState , showDrawer : false})}/>:
              <span onClick={()=> setState({...fetchState , showDrawer : true})}><RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} /></span> 
              }
          </div>
           {renderParamInfoPage()}        
          </>
       } 
        return (
          <div className='w-100 d-flex relative'>
              <div className='shadow' style={{width:"4%", position:"relative", zIndex:"5"}}>
                   <VcSideNav/>
              </div>
              <div style = {{width:"96%"}}>
               {renderParamInfoPage()}        
              </div>
          </div>
        )
}

export default VcParameterDocumentation;
