import React, { Component } from 'react'
import "../CSS/AddUserForm.css";
import { getAPIHostURL } from '../../ClientConfig';
import axios from 'axios';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_Close, IDS_RegistNetworkError, IDS_DeviceID, IDS_AUServerError, IDS_AUSrvrIssueReqParamsNotSent, IDS_DeviceDataMissing, 
         IDS_DTDeviceProfile, IDS_DeviceName, IDS_DevicePincode, IDS_HardwareID, IDS_HardwareModel, IDS_FirmwareID,IDS_AlertDeviceInfoUpdated, 
         IDS_FirmwareName, IDS_FirmwareVersion, IDS_DeviceOwnerName, IDS_Save, IDS_DeviceVicinityType, IDS_Edit, IDS_DataSendIntervalTime,
         IDS_TimeInMinutes, IDS_Minutes, IDS_NoteDataSendIntervalTime, IDS_NotHaveReqPvgToEditDeviceProf, IDS_SubStatus, IDS_SubDaysRemaining,
         IDS_Expired, IDS_ExpiresToday, IDS_ExpiresInDays, IDS_SubRechargeDueOn, IDS_RechargeSubOnOrBefore, IDS_SubNotApplicable, 
         IDS_LoginServerIssue, IDS_CountryCode, IDS_CloseDeviceProfilePopup, IDS_EditDeviceProfilePopup, IDS_SaveDeviceProfilePopup, IDS_TotalNoOfToiletSeats, IDS_toiletSection, IDS_enterValidTotalToiletSeats,
         IDS_EmptyDeviceName,
         IDS_EmptyDevicePinCode} from '../../VcLanguage';
import VcSetupDevice from './VcSetupDevice';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../CSS/NotificationSetting.css';
import { MIN_DATA_SEND_INTERVAL_TIME, MAX_DATA_SEND_INTERVAL_TIME, PROD_DEVC_OWNER_USER_ID, MIN_DATA_SEND_INTERVAL_TIME_FOR_PROD_DVC_OWNER, DEVICE_TYPE_PFC, WOMEN_TOILET_SECTION, MEN_TOILET_SECTION } from '../../VcConstants';
import { getLocalTimezoneOffset } from '../../vtUtil';

// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const SliderWithTooltip = createSliderWithTooltip(Slider);

class VcDeviceProfile extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            notifyOnCloseForm: this.props.OnCloseDeviceProfilePopup,
            SelectedDeviceName: this.props.DeviceName,
            SelectedDeviceID: this.props.DeviceID,
            DeviceProfileInfo: [],
            DeviceName: '',
            DevicePinCode: '',
            HardwareModel: '',
            DeviceType: '',
            HardwareId: '',
            SoftwareId: '',
            SoftwareName: '',
            SoftwareVersion: '',
            DeviceUserFirstName: '',
            DeviceUserLastName: '',
            DeviceVicinityType: '',
            totalToiletSeats: '',
            toiletSection: '',
            originalTotalToiletSeats: '',
            originalToiletSection: '',
            allToiletSections: [],
            showEditableDevcProfileForm: false,
            AllDeviceVicinityTypes: [],
            OriginalDeviceName: '',
            OriginalVicinityType: '',
            OriginalPinCode: '',
            DataSendIntervalTime: '',
            OriginalDataSendIntervalTime: '',
            SubscriptionRemaningDays: 0,
            SubscriptionNxtRechargeDate: '',
            NxtRechargeDtTmAndRemaningSubDaysArr: [],
            CountryInfo: [],
            DevcCountryCode: "",
            OriginalCountryCode: "",
            IsSubscriptionInWarningPeriod: false,
            errors: {
                others: '',
                deviceName: '',
                devicePinCode: '',
                totalToiletSeats: ''
            }  
        }
    }

    componentDidMount () {
        let appRelevantDataContextValue = this.context;

        appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);
        this.getAllCountryCodeInfo();

        // To Get Device Profile Information.
        this.getDeviceProfileInfo();
    }

    onChangeCountryCode = (e) => {
        let modifiedState = this.state;
        modifiedState.DevcCountryCode = e.target.value;
        modifiedState.errors.others = "";
        this.setState(modifiedState);
    }
    
    getAllCountryCodeInfo = () => {
    
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;
    
        axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformationForReg`)
        .then(response => {
          if(response.data.code == "SUCCESS") {
              modifiedState.CountryInfo = [];
              modifiedState.CountryInfo = response.data.CountryInfo;
          } else {
    
            if(response.data.code == "SQL_ERROR"){
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
          }
          this.setState(modifiedState);
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            this.setState(modifiedState);
          } 
        });
    }
    
    getDeviceProfileInfo = () => {

        let modifiedState = this.state

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 

        // To avoid appending of array values.
        modifiedState.DeviceProfileInfo = [];
        modifiedState.DeviceName = '';
        modifiedState.DevicePinCode = '';
        modifiedState.HardwareModel = '';
        modifiedState.DeviceType = '';
        modifiedState.HardwareId = '';
        modifiedState.SoftwareId = '';
        modifiedState.SoftwareName = '';
        modifiedState.SoftwareVersion = '';
        modifiedState.DeviceUserFirstName = '';
        modifiedState.DeviceUserLastName = '';
        modifiedState.errors.others = '';
        modifiedState.DeviceVicinityType = '';
        modifiedState.totalToiletSeats = '';
        modifiedState.toiletSection = '';
        modifiedState.originalTotalToiletSeats = '';
        modifiedState.originalToiletSection = '';
        modifiedState.AllDeviceVicinityTypes = [];
        modifiedState.allToiletSections = [];
        modifiedState.OriginalDeviceName = '';
        modifiedState.OriginalVicinityType = '';
        modifiedState.OriginalCountryCode = "";
        modifiedState.DevcCountryCode = "";
        modifiedState.OriginalPinCode = '';
        modifiedState.OriginalDataSendIntervalTime = '';
        modifiedState.DataSendIntervalTime = '';
        modifiedState.SubscriptionRemaningDays = 0;
        modifiedState.SubscriptionNxtRechargeDate = '';
        modifiedState.errors.others = "";
        modifiedState.IsSubscriptionInWarningPeriod = false;

        let localTimeZone = (new Date()).getTimezoneOffset();

        const DeviceData = {
            DeviceID : modifiedState.SelectedDeviceID,
            LocalTimeZone: getLocalTimezoneOffset(localTimeZone),
        }

        // To get information related to Device Profile from Database.
        axios.post(`${getAPIHostURL()}/wclient/getDeviceProfileInfo`, DeviceData)
        .then(response => {
            if(response.data.code == "SUCCESS") { 

                if(response.data["retrievedDeviceProfileInfo"] == null || response.data["retrievedDeviceProfileInfo"].length <= 0) {
                    modifiedState.errors.others = t(IDS_DeviceDataMissing);
                } else {
                    modifiedState.DeviceProfileInfo = (response.data.retrievedDeviceProfileInfo != null && response.data.retrievedDeviceProfileInfo.length > 0) ?
                                                       response.data.retrievedDeviceProfileInfo : [];

                    if(response.data.NxtRechargeDtTmAndRemainingSubDays != null && response.data.NxtRechargeDtTmAndRemainingSubDays.length > 0) {
                        modifiedState.NxtRechargeDtTmAndRemaningSubDaysArr = response.data.NxtRechargeDtTmAndRemainingSubDays;
                        modifiedState.SubscriptionRemaningDays = parseInt(modifiedState.NxtRechargeDtTmAndRemaningSubDaysArr[0].NumberOfSubRemainingDays);
                        modifiedState.SubscriptionNxtRechargeDate = modifiedState.NxtRechargeDtTmAndRemaningSubDaysArr[0].NxtRechargeDtTm;
                        modifiedState.IsSubscriptionInWarningPeriod = (modifiedState.SubscriptionRemaningDays - modifiedState.NxtRechargeDtTmAndRemaningSubDaysArr[0].WarningPeriod) < 0 ? true : false;
                    } else {
                        modifiedState.NxtRechargeDtTmAndRemaningSubDaysArr = [];
                    }

                    modifiedState.DeviceName = modifiedState.DeviceProfileInfo[0].DeviceName;
                    modifiedState.OriginalDeviceName = modifiedState.DeviceProfileInfo[0].DeviceName;
                    modifiedState.OriginalPinCode = modifiedState.DeviceProfileInfo[0].Pincode;
                    modifiedState.OriginalCountryCode = modifiedState.DeviceProfileInfo[0].CountryCode;
                    modifiedState.OriginalVicinityType = (modifiedState.DeviceProfileInfo[0].VicinityType == null || 
                                                            modifiedState.DeviceProfileInfo[0].VicinityType.length <= 0
                                                        ) ? 'NA' : modifiedState.DeviceProfileInfo[0].VicinityType;
                    modifiedState.DevicePinCode = modifiedState.DeviceProfileInfo[0].Pincode;
                    modifiedState.HardwareModel = modifiedState.DeviceProfileInfo[0].ModelName;
                    modifiedState.DeviceType = modifiedState.DeviceProfileInfo[0].DeviceType;
                    modifiedState.HardwareId = modifiedState.DeviceProfileInfo[0].ModelID;
                    modifiedState.SoftwareId = modifiedState.DeviceProfileInfo[0].SoftwareID;
                    modifiedState.SoftwareName = modifiedState.DeviceProfileInfo[0].SoftwareName;
                    modifiedState.SoftwareVersion = modifiedState.DeviceProfileInfo[0].Version;
                    modifiedState.DeviceUserFirstName = modifiedState.DeviceProfileInfo[0].FirstName;
                    modifiedState.DeviceUserLastName = modifiedState.DeviceProfileInfo[0].LastName;
                    modifiedState.DeviceVicinityType = (modifiedState.DeviceProfileInfo[0].VicinityType == null || 
                                                        modifiedState.DeviceProfileInfo[0].VicinityType.length <= 0
                                                    ) ? 'NA' : modifiedState.DeviceProfileInfo[0].VicinityType;
                    modifiedState.totalToiletSeats = modifiedState.DeviceProfileInfo[0].TotalToiletSeats;
                    modifiedState.toiletSection = modifiedState.DeviceProfileInfo[0].ToiletSection;
                    modifiedState.originalTotalToiletSeats = modifiedState.DeviceProfileInfo[0].TotalToiletSeats;
                    modifiedState.originalToiletSection = modifiedState.DeviceProfileInfo[0].ToiletSection;
                    modifiedState.allToiletSections = modifiedState.toiletSection == MEN_TOILET_SECTION ? [WOMEN_TOILET_SECTION] : modifiedState.toiletSection == WOMEN_TOILET_SECTION ? [MEN_TOILET_SECTION] : [];
                    modifiedState.DataSendIntervalTime = modifiedState.DeviceProfileInfo[0].DataSendIntervalTime;

                    modifiedState.OriginalDataSendIntervalTime = modifiedState.DeviceProfileInfo[0].DataSendIntervalTime;
                    modifiedState.AllDeviceVicinityTypes = response.data.AllVicinityTypes;
                    modifiedState.DevcCountryCode = (modifiedState.DeviceProfileInfo[0].CountryCode == null || 
                                                            modifiedState.DeviceProfileInfo[0].CountryCode.length <= 0
                                                        ) ? 'NA' : modifiedState.DeviceProfileInfo[0].CountryCode;
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that server is experiencing issues (required params are not found).
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent); // TODO: Define generic language error for this
                }
                else if(response.data.code == "SQL_ERROR") {
                    modifiedState.errors.others = t(IDS_AUServerError); 

                } else if(response.data.code == "DEVICE_DATA_MISSING") {
                    // modifiedState.errors.others = "Device Profile Information Missing";
                    modifiedState.errors.others = t(IDS_DeviceDataMissing);

                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);  
            }
        });
    }

    OnCloseDeviceProfilePopup = (e) => {
        this.state.notifyOnCloseForm();
    }

    OnEditDeviceProfilePopup = (e) => {
        let modifiedState = this.state;
        modifiedState.showEditableDevcProfileForm = true;
        this.setState(modifiedState);
    }

    onChangeDeviceName = (e) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        let modifiedState = this.state;
        modifiedState.DeviceName = e.target.value;

        if(modifiedState.DeviceName.length > 0) {
            modifiedState.errors.deviceName = "";
        } else {
            modifiedState.errors.deviceName = t(IDS_EmptyDeviceName);
        }

        this.setState(modifiedState);
    }

    onChangeDeviceVicinityType = (e) => {
        let modifiedState = this.state;
        modifiedState.DeviceVicinityType = e.target.value;
        this.setState(modifiedState);
    }

    onChangeDevicePinCode = (e) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        let modifiedState = this.state;
        const value = e.target.value;

        // The regex below verifies & ensures that the entire string consists of digits & only digits are allowed in the input tag element
        if (/^\d*$/.test(value)) {
            modifiedState.DevicePinCode = value;

            if(modifiedState.DevicePinCode.length > 0) {
                modifiedState.errors.devicePinCode = "";
            } else {
                modifiedState.errors.devicePinCode = t(IDS_EmptyDevicePinCode);
            }

            this.setState(modifiedState);
        }
    }

    onChangeTotalToiletSeats = (e) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        let modifiedState = this.state;
        let toiletSeats = e.target.value;

        modifiedState.totalToiletSeats = (toiletSeats.length > 0 &&  toiletSeats >= 0) ? toiletSeats : "";
        modifiedState.errors.totalToiletSeats = (toiletSeats.length > 0 &&  toiletSeats >= 0) ? "" : t(IDS_enterValidTotalToiletSeats);

        this.setState(modifiedState);
    }

    onChangeToiletSection = (e) => {
        let modifiedState = this.state;
        modifiedState.toiletSection = e.target.value;
        modifiedState.allToiletSections = modifiedState.toiletSection == MEN_TOILET_SECTION ? [WOMEN_TOILET_SECTION] : modifiedState.toiletSection == WOMEN_TOILET_SECTION ? [MEN_TOILET_SECTION] : [];

        this.setState(modifiedState);
    }

    OnSaveDeviceProfilePopup = () => {

        let modifiedState = this.state

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        let loggedInUserEmailId = appRelevantDataContextValue.loggedInUserInfo.userID;

        modifiedState.errors.others = "";
        modifiedState.errors.deviceName = "";
        modifiedState.errors.devicePinCode = "";

        if(appRelevantDataContextValue.selectedNodeInfo.nodeID == modifiedState.SelectedDeviceID){

            let isRoot = appRelevantDataContextValue.selectedNodeInfo.isRoot;
            let hasDevc = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
            let isDevc = appRelevantDataContextValue.selectedNodeInfo.isDevc;
            let deviceType = appRelevantDataContextValue.selectedNodeInfo.deviceType;
            let parentID = appRelevantDataContextValue.selectedNodeInfo.parentID;
            let SelectedNodeDeviceType = appRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType;
            let nodePath = appRelevantDataContextValue.selectedNodeInfo.nodePath;

            // Extract and store whole node info except modified DeviceName as it is.
            appRelevantDataContextValue.onSelectedDevice(modifiedState.SelectedDeviceID, modifiedState.DeviceName, isRoot, hasDevc, isDevc, deviceType, parentID, SelectedNodeDeviceType, nodePath);
        }

        // CASE: Return the user if the information is not changed or modified and is untouched in Device Profile "Edit" Section
        if((modifiedState.OriginalDeviceName == modifiedState.DeviceName && 
                modifiedState.OriginalVicinityType == modifiedState.DeviceVicinityType &&
                modifiedState.OriginalPinCode == modifiedState.DevicePinCode && 
                modifiedState.OriginalDataSendIntervalTime == modifiedState.DataSendIntervalTime && 
                modifiedState.OriginalCountryCode == modifiedState.DevcCountryCode &&
                modifiedState.originalTotalToiletSeats == modifiedState.totalToiletSeats && 
                modifiedState.originalToiletSection == modifiedState.toiletSection) 
            || (modifiedState.errors.others != null && modifiedState.errors.others.length > 0)){
                
            // no need to call axios since the user did not change the device's information.
            modifiedState.showEditableDevcProfileForm = false;
            return; // No further processing required
        }

        // Case: If Device information is being updated or modified, then empty fields are not processed further to make the API call in Device Profile "Edit" Section
        if((modifiedState.DeviceName != null && modifiedState.DeviceName.length <= 0) || modifiedState.DeviceName == null) {
            modifiedState.errors.deviceName = t(IDS_EmptyDeviceName);
        }

        if((modifiedState.DevicePinCode != null && modifiedState.DevicePinCode.length <= 0) || modifiedState.DevicePinCode == null) {
            modifiedState.errors.devicePinCode = t(IDS_EmptyDevicePinCode);
        }

        if((modifiedState.errors.deviceName != null && modifiedState.errors.deviceName.length > 0) || (modifiedState.errors.devicePinCode != null && modifiedState.errors.devicePinCode.length > 0)) {
            this.setState(modifiedState);
            return; // No further processing required
        }

        const DeviceProfileData = {
            DeviceID : modifiedState.SelectedDeviceID,
            DevicePinCode: modifiedState.DevicePinCode,
            DeviceVicinityType: modifiedState.DeviceVicinityType,
            DeviceName: modifiedState.DeviceName,
            TotalToiletSeats: modifiedState.totalToiletSeats,
            ToiletSection: modifiedState.toiletSection,
            DataSendIntervalTime: modifiedState.DataSendIntervalTime,
            LoggedInUserEmailId: loggedInUserEmailId,
            CountryCode: modifiedState.DevcCountryCode
        }

        // To update information related to Device Profile in to Database.
        axios.post(`${getAPIHostURL()}/wclient/updateDeviceProfileInfo`, DeviceProfileData)
        .then(response => {
            if(response.data.code == "SUCCESS") { 

                alert(t(IDS_AlertDeviceInfoUpdated));

                this.state.notifyOnCloseForm();
                modifiedState.DeviceProfileInfo = [];
                modifiedState.DeviceName = "";
                modifiedState.DevicePinCode = "";
                modifiedState.HardwareModel = "";
                modifiedState.DeviceType = "";
                modifiedState.HardwareId = "";
                modifiedState.SoftwareId = "";
                modifiedState.SoftwareName = "";
                modifiedState.SoftwareVersion = "";
                modifiedState.DeviceUserFirstName = "";
                modifiedState.DeviceUserLastName = "";
                modifiedState.DeviceVicinityType = "";
                modifiedState.totalToiletSeats = "";
                modifiedState.toiletSection = "";
                modifiedState.originalTotalToiletSeats = "";
                modifiedState.originalToiletSection = "";
                modifiedState.allToiletSections= [];
                modifiedState.showEditableDevcProfileForm = false;
                modifiedState.AllDeviceVicinityTypes = [];
                modifiedState.OriginalDeviceName = '';
                modifiedState.OriginalVicinityType = '';
                modifiedState.OriginalPinCode = '';
                modifiedState.DataSendIntervalTime = '';
                modifiedState.OriginalDataSendIntervalTime = '';
                modifiedState.errors.others = "";
                modifiedState.errors.deviceName = "";
                modifiedState.errors.devicePinCode = "";

                this.setState(modifiedState);
                window.location.reload(); 

            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that server is experiencing issues (required params are not found).
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent); // TODO: Define generic language error for this
                } else if(response.data.code == "DONT_HAVE_REQ_PVG") {
                    alert(t(IDS_NotHaveReqPvgToEditDeviceProf, loggedInUserEmailId));  
                } else if(response.data.code == "SQL_ERROR") {
                    modifiedState.errors.others = t(IDS_AUServerError); 
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                }
                this.setState(modifiedState);
            }
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);  
            }
        });

    }

    handleVcSliderChange = (value) => {
        
        let modifiedState = this.state;
        modifiedState.DataSendIntervalTime = value;
        this.setState(modifiedState);   
    }

    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 
        let loggedInUserEmailId = appRelevantDataContextValue.loggedInUserInfo.userID;
        let modifiedState = this.state;

        return (
            <div className="deviceProfileBlackfilm" style={{overflowX: "scroll"}}>    
                <div className="container">
                    <div className="row">
                        <div className="container col-lg-7 col-lg-offset-4
                                                    col-md-7 col-md-offset-3">

                            <div className="mvAddScroll">
                                <div className="adduser-popup">
                                    <form>       
                                        <div className="popup-scroll">
                                        <h6 className="deviceDetail"> {t(IDS_DTDeviceProfile)} </h6>
                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_DeviceID)} : </label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    {modifiedState.showEditableDevcProfileForm == true ? 
                                                        <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                defaultValue={modifiedState.SelectedDeviceID}
                                                                disabled
                                                        />
                                                        :
                                                        <label className="adduser-form-label">{modifiedState.SelectedDeviceID}</label>
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_DeviceName)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    {modifiedState.showEditableDevcProfileForm == true ? 
                                                        <>
                                                            <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                    value={modifiedState.DeviceName}
                                                                    onChange={this.onChangeDeviceName}
                                                            />
                                                            {(modifiedState.errors.deviceName != null && modifiedState.errors.deviceName.length > 0) &&
                                                                <p style={{color: 'var(--errorColor)', fontSize: '0.73rem', marginLeft: "0.3rem"}}>{modifiedState.errors.deviceName}</p>}
                                                        </>
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.DeviceName}</label>
                                                    }
                                                </div>
                                            </div>
                                            
                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_DeviceOwnerName)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    {modifiedState.showEditableDevcProfileForm == true ? 
                                                        <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                defaultValue={modifiedState.DeviceUserFirstName +" " +modifiedState.DeviceUserLastName}
                                                                disabled
                                                        />
                                                        :
                                                        <label className="adduser-form-label">{modifiedState.DeviceUserFirstName} {modifiedState.DeviceUserLastName}</label>  
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_DevicePincode)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ? 
                                                        <>
                                                            <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                    value={modifiedState.DevicePinCode}
                                                                    onChange={this.onChangeDevicePinCode}
                                                            />
                                                            {(modifiedState.errors.devicePinCode != null && modifiedState.errors.devicePinCode.length > 0) &&
                                                                <p style={{color: 'var(--errorColor)', fontSize: '0.73rem', marginLeft: "0.3rem"}}>{modifiedState.errors.devicePinCode}</p>}
                                                        </>
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.DevicePinCode}</label>
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_DeviceVicinityType)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ?
                                                        <select className="input-Deviceprofile" 
                                                            required
                                                            value={modifiedState.DeviceVicinityType}
                                                            onChange={this.onChangeDeviceVicinityType}
                                                        >
                                                            {(modifiedState.AllDeviceVicinityTypes).map((singleVicinityType, index) => 
                                                                <option key={index} value={singleVicinityType["VicinityType"]}>{singleVicinityType["VicinityType"]}</option>)}
                                                        </select> 
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.DeviceVicinityType}</label>
                                                    }
                                                </div>
                                            </div>

                                            {modifiedState.DeviceType == DEVICE_TYPE_PFC
                                            ?
                                            <div>
                                                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                    <div style={{flex:"1", textAlign: "left"}}>
                                                        <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_toiletSection)} :</label>
                                                    </div>
                                                    <div style={{flex:"1", textAlign: "left"}}>
                                                        { modifiedState.showEditableDevcProfileForm == true ?
                                                            <select className="input-Deviceprofile" 
                                                                required
                                                                value={modifiedState.toiletSection}
                                                                onChange={this.onChangeToiletSection}
                                                            >
                                                            <option value={modifiedState.toiletSection} select= "true">{modifiedState.toiletSection}</option> 
                                                            {(modifiedState.allToiletSections).map((singleToiletSection, index) => 
                                                            <option key={index} value={singleToiletSection}>{singleToiletSection}</option>)}
                                                            </select> 
                                                            :
                                                            <label className="adduser-form-label" >{modifiedState.toiletSection}</label>
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                    <div style={{flex:"1", textAlign: "left"}}>
                                                        <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_TotalNoOfToiletSeats)} :</label>
                                                    </div>
                                                    <div style={{flex:"1", textAlign: "left"}}>
                                                        { modifiedState.showEditableDevcProfileForm == true ? 
                                                            <>
                                                                <input type="number" className="input-Deviceprofile" name="name" required="required"
                                                                        value={modifiedState.totalToiletSeats}
                                                                        onChange={this.onChangeTotalToiletSeats}
                                                                />
                                                                {(modifiedState.errors.totalToiletSeats != null && modifiedState.errors.totalToiletSeats.length > 0) &&
                                                                <p style={{color: 'var(--errorColor)', fontSize: '0.73rem', marginLeft: "0.3rem"}}>{modifiedState.errors.totalToiletSeats}</p>}
                                                            </>
                                                            :
                                                            <label className="adduser-form-label" >{modifiedState.totalToiletSeats}</label>
                                                        }
                                                    </div>
                                                </div>  
                                            </div>
                                            :
                                            null
                                            }
                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_HardwareID)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ? 
                                                        <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                defaultValue={modifiedState.HardwareId}
                                                                disabled
                                                        />
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.HardwareId}</label>
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_HardwareModel)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ? 
                                                        <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                defaultValue={modifiedState.HardwareModel}
                                                                disabled
                                                        />
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.HardwareModel}</label>
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_FirmwareID)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ? 
                                                        <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                defaultValue={modifiedState.SoftwareId}
                                                                disabled
                                                        />
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.SoftwareId}</label>
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_FirmwareName)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ? 
                                                        <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                defaultValue={modifiedState.SoftwareName}
                                                                disabled
                                                        />
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.SoftwareName}</label>
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold",}}>{t(IDS_FirmwareVersion)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ? 
                                                        <input type="text" className="input-Deviceprofile" name="name" required="required"
                                                                defaultValue={modifiedState.SoftwareVersion}
                                                                disabled
                                                        />
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.SoftwareVersion}</label>
                                                    }
                                                </div>
                                            </div>

                                            <div style={{display: "flex", paddingBottom: "0.1rem"}} hidden={true}>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_CountryCode)} :</label>
                                                </div>
                                                <div style={{flex:"1", textAlign: "left"}}>
                                                    { modifiedState.showEditableDevcProfileForm == true ? 
                                                        <select className="input-Deviceprofile" 
                                                                            value={this.state.DevcCountryCode}
                                                                            onChange={this.onChangeCountryCode}
                                                        >
                                                            <option value={this.state.DevcCountryCode} select= "true">{this.state.DevcCountryCode}</option>
                                                            {(this.state.CountryInfo).map((singleCountryCode, index) => 
                                                            <option key={index} value={singleCountryCode["CountryCode"]}>
                                                            {singleCountryCode["CountryCode"]}
                                                            {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                            {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                            {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                            {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                        </select>
                                                        :
                                                        <label className="adduser-form-label" >{modifiedState.DevcCountryCode}</label>
                                                    }
                                                </div>
                                            </div>

                                            { modifiedState.showEditableDevcProfileForm == false ? 
                                                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                    <div style={{flex:"1", textAlign: "left"}}>
                                                        <label className="adduser-form-label" style={{fontWeight: "bold"}}>{t(IDS_DataSendIntervalTime)} :</label><br/>
                                                    </div>
                                                    <div style={{flex:"1", textAlign: "left"}}>
                                                            <label style={{flex:"1", textAlign: "left"}} className="adduser-form-label" >{this.state.DataSendIntervalTime} {t(IDS_Minutes)}</label>
                                                    </div>
                                                </div>
                                                :
                                                <div/>
                                            }

                                            <div style={{marginLeft: "0.8rem", marginRight: "0.8rem", fontSize: "0.9rem"}}
                                                 hidden= {modifiedState.showEditableDevcProfileForm == true ? false : true}>
                                                { modifiedState.showEditableDevcProfileForm == true ? 
                                                    <div className="rangesettingparameter" style={{marginTop: "0rem"}}>
                                                        <div  style={{display: "flex"}}>
                                                            <label className="adduser-form-label" style={{flex:"1", fontWeight: "bold", float: "left", paddingTop: "0.2rem", marginLeft: "0.7rem"}}>
                                                                {t(IDS_DataSendIntervalTime)} :
                                                            </label>
                                                            <div style={{flex:"1", float: "left", textAlign: "left", marginTop: "0.2rem", marginRight: "1rem", fontSize: "0.9rem"}}>{t(IDS_TimeInMinutes)} :&nbsp; 
                                                                <input type="number" 
                                                                    value={this.state.DataSendIntervalTime}
                                                                    placeholder="NA"
                                                                    disabled={true} 
                                                                    className="rangesettingonlyoneinputtab"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: "0.5rem",marginBottom: "0.5rem", marginLeft: "1rem", marginRight: "1rem"}}>
                                                            <Slider
                                                                min={loggedInUserEmailId != null && loggedInUserEmailId == PROD_DEVC_OWNER_USER_ID
                                                                ? MIN_DATA_SEND_INTERVAL_TIME_FOR_PROD_DVC_OWNER
                                                                : MIN_DATA_SEND_INTERVAL_TIME
                                                                }
                                                                max={MAX_DATA_SEND_INTERVAL_TIME} 
                                                                defaultValue={this.state.DataSendIntervalTime}
                                                                onChange={this.handleVcSliderChange}
                                                                handleStyle={{backgroundColor: "var(--secondaryColor)", borderColor: "var(--secondaryColor)"}}
                                                                trackStyle={{backgroundColor: "var(--secondaryColor)"}}
                                                                style={{backgroundColor:"transparent"}}
                                                            /> 
                                                        </div>
                                                        <div style={{display: modifiedState.showEditableDevcProfileForm == true ? true : "none", marginTop: "1rem", marginLeft: "1rem", marginRight: "1rem", fontSize: "0.9rem"}}>
                                                            <label dangerouslySetInnerHTML={{__html: t(IDS_NoteDataSendIntervalTime)}}></label>        
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>

                                            <div style = {{display: modifiedState.showEditableDevcProfileForm == true ? "none" : "block", falseboxSizing:"border-box", border: "1px solid #ABADB3", 
                                                    borderRadius:"0.5rem" , paddingTop:"0.1rem", paddingBottom:"0.1rem", paddingLeft:"0rem", paddingRight:"0rem", textAlign:"left", 
                                                    marginTop:"1rem", marginLeft: "0.8rem", marginRight: "0.8rem", background:"white"
                                                }}
                                            >
                                                {this.state.NxtRechargeDtTmAndRemaningSubDaysArr.length > 0 ? 
                                                    <div>
                                                        <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                            <div style={{flex:"1", textAlign: "left"}}>
                                                                <label className="adduser-form-label" 
                                                                    style={{fontWeight: "bold", marginLeft: "0.7rem", 
                                                                        color: modifiedState.SubscriptionRemaningDays < 0 || (new Date(modifiedState.SubscriptionNxtRechargeDate) < new Date()) == true ? "var(--errorColor)" : 
                                                                            modifiedState.IsSubscriptionInWarningPeriod == true ? "var(--secondaryColor)" : "black"    
                                                                    }}
                                                                >
                                                                    {(modifiedState.SubscriptionRemaningDays <= 0 || modifiedState.IsSubscriptionInWarningPeriod == true) ? t(IDS_SubStatus)  + " :" :  t(IDS_SubDaysRemaining) + " :"}
                                                                </label>
                                                            </div>
                                                            <div style={{flex:"1", textAlign: "left"}}>
                                                                <label className="adduser-form-label"
                                                                    style={{color: modifiedState.SubscriptionRemaningDays < 0 || (new Date(modifiedState.SubscriptionNxtRechargeDate) < new Date()) == true ? "var(--errorColor)" : 
                                                                        (modifiedState.SubscriptionRemaningDays == 0 || modifiedState.IsSubscriptionInWarningPeriod == true) ? "var(--secondaryColor)" : "black"    
                                                                    }}
                                                                >
                                                                    {modifiedState.SubscriptionRemaningDays < 0 || (new Date(modifiedState.SubscriptionNxtRechargeDate) < new Date()) == true ? t(IDS_Expired) : 
                                                                        modifiedState.SubscriptionRemaningDays == 0 ? t(IDS_ExpiresToday) :
                                                                        modifiedState.IsSubscriptionInWarningPeriod == true ? t(IDS_ExpiresInDays, modifiedState.SubscriptionRemaningDays) :
                                                                        modifiedState.SubscriptionRemaningDays
                                                                    }
                                                                </label>  
                                                            </div>
                                                        </div>

                                                        <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                                            <div style={{flex:"1", textAlign: "left"}}>
                                                                <label className="adduser-form-label" style={{fontWeight: "bold", marginLeft: "0.7rem"}}>
                                                                    {modifiedState.SubscriptionRemaningDays < 0 || (new Date(modifiedState.SubscriptionNxtRechargeDate) < new Date()) == true ? t(IDS_SubRechargeDueOn) +" :" : t(IDS_RechargeSubOnOrBefore) + " :"}
                                                                </label>
                                                            </div>
                                                            <div style={{flex:"1", textAlign: "left"}}>
                                                                <label className="adduser-form-label">{modifiedState.SubscriptionNxtRechargeDate}</label>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    <div style={{paddingTop: "0.4rem", marginLeft: "0.7rem", fontSize: "1rem", color: "var(--primaryColor)"}}>
                                                        <label> 
                                                            {t(IDS_SubNotApplicable)}
                                                        </label>   
                                                    </div>
                                                }
                                            </div>

                                            <div style={{display: modifiedState.showEditableDevcProfileForm == true ? "none" : "block", marginTop: "1rem"}}>
                                                <VcSetupDevice invokedFrom = "DeviceProfile"/>
                                            </div>

                                            <div className="form-group update-form">
                                                <span>
                                                    {modifiedState.showEditableDevcProfileForm == true ?
                                                        <button type="button"
                                                                title={t(IDS_SaveDeviceProfilePopup)}
                                                                className="adduserButton btn-lg"
                                                                onClick={this.OnSaveDeviceProfilePopup}
                                                        >{t(IDS_Save)}</button>
                                                    :
                                                        <button type="button" 
                                                            title={t(IDS_EditDeviceProfilePopup)}
                                                            className="adduserButton btn-lg"
                                                            onClick={this.OnEditDeviceProfilePopup}
                                                        >{t(IDS_Edit)}</button>
                                                    }
                                                    <button type="button" 
                                                            title={t(IDS_CloseDeviceProfilePopup)}
                                                            className="adduserButton btn-lg"
                                                            onClick={this.OnCloseDeviceProfilePopup}
                                                    > 
                                                            {t(IDS_Close)}
                                                    </button>
                                                    <div className = "buttonErrorMessage">
                                                        {this.state.errors.others.length > 0 && 
                                                        <p style={{color: 'var(--errorColor)', fontSize: '0.8rem'}} className='error'>{modifiedState.errors.others}</p>}
                                                    </div> 
                                                </span>
                                            </div>                                    
                                        </div>
                                    </form>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

VcDeviceProfile.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcDeviceProfile


